import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import Modals from "./Modals";
import step1 from "../assets/img/1f@2x.jpg";
import step2 from "../assets/img/2f@2x.jpg";
import step3 from "../assets/img/3f@2x.jpg";
import step4 from "../assets/img/4f@2x.jpg";
import advantages1 from "../assets/img/advantages1.jpg";
import advantages2 from "../assets/img/advantages2.jpg";
import advantages3 from "../assets/img/advantages3.jpg";

import "../assets/css/App.scss";
export default class FeedingRecommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faq: 0
        }
        this.btnFeedbackShow = this.btnFeedbackShow.bind(this);
        this.info5Show = this.info5Show.bind(this);
    }
    componentDidMount() {
        const el3 = document.getElementById('menu');
        el3.scrollIntoView();
    }

    componentWillUnmount() {
        const el = document.getElementById('menu');
        el.scrollIntoView({ behavior: "smooth" });

    }
    info5Show = () => {
        this.modals.info5Show();
    }
    btnFeedbackShow = () => {
        this.modals.feedbackShow();
    }
    btnReturn = () => {
        return (
            <Link to="/" className="btn-return">Оформить заявку на возврат</Link>
            )
        }
    toggleFaq = (numb) => {
        if (this.state.faq === numb) {
            this.setState({
                faq: 0
            })
        } else {
            this.setState({
                faq: numb
            })
        }
    }

    render() {
        return (
            <div className="app">
                <Header page={"feeding-recommendations"}  info5Show={this.info5Show}/>
                <div className="section-reasons-refusal">
                    <div className="container">
                        <div className="section-title">Частые причины отказа от&nbsp;корма</div>
                        <p>Привычки играют важную роль в&nbsp;жизни собак и&nbsp;кошек, поэтому им&nbsp;может понадобиться Ваша помощь, чтобы перейти на&nbsp;новый корм. Это совершенно нормально, если Ваш питомец отказывается от&nbsp;нового корма в&nbsp;первый раз, особенно когда у&nbsp;него ранее был другой тип питания (например, питомец ел&nbsp;только влажные корма).</p>
                        <p>Перемена рациона по-разному может отразиться на&nbsp;разных домашних животных, поэтому главное&nbsp;&mdash; сделать этот переход постепенным. Смешивайте прежний и&nbsp;новый корм, постепенно добавляя всё больше и&nbsp;больше нового и&nbsp;уменьшая объем предыдущего. Так питомец будет постепенно привыкать к&nbsp;новому вкусу, запаху, текстуре и&nbsp;размеру кусочков или гранул. Также такой постепенный переход может уменьшить риск развития расстройства пищеварения у&nbsp;вашего питомца. Если Ваш ветеринарный специалист не&nbsp;рекомендовал иное, постепенно вводите новый рацион в&nbsp;течение 7&nbsp;дней.</p>
                    </div>
                </div>
                <div className="section-transition-rules">
                    <div className="container">
                        <div className="section-title">Как перевести Вашего&nbsp;питомца <br />на новый корм?</div>
                        <ul>
                            <li>Постепенно вводите новый корм в&nbsp;течение 7&nbsp;дней;</li>
                            <li>Добавляйте новый корм к&nbsp;предыдущему, постепенно увеличивая долю нового до&nbsp;полного замещения предыдущего;</li>
                            <li>Всегда обращайтесь к&nbsp;инструкции по&nbsp;кормлению, указанной на&nbsp;упаковке с&nbsp;кормом.</li>
                        </ul>
                        <div className="section-transition-rules-items">
                            <div className="section-transition-rules-item"><img src={step1} /></div>
                            <div className="section-transition-rules-item"><img src={step2} /></div>
                            <div className="section-transition-rules-item"><img src={step3} /></div>
                            <div className="section-transition-rules-item"><img src={step4} /></div>
                        </div>
                    </div>
                </div>
                <Footer page={"home"} btnFeedbackShow={this.btnFeedbackShow} info5Show={this.info5Show} />
                <Modals runWidget={this.runWidget} ref={instance => { this.modals = instance; }} />
            </div>
        );
    }
}
