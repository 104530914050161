import React from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask'
import minBgDog from '../assets/img/min-bg-dog.jpg';
import loader from '../assets/img/ajax-loader (3).gif';
import Modals from "./Modals";
import logoHeader from '../assets/img/hils_new_large_logo.png';
import * as constants from '../constants';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            submit: false,
            loading: false,
            passType: 'password',
            data: {
                reg: {
                    surname: '',
                    surnameFocus: false,
                    name: '',
                    nameFocus: false,
                    patronymic: '',
                    patronymicFocus: false,
                    email: '',
                    emailFocus: false,
                    phone: '',
                    phoneFocus: false,
                    agree2: false
                }
            },
            errors: {
                reg: {
                    surname: false,
                    surnameText: 'Введена некорректная фамилия',
                    name: false,
                    nameText: 'Введено некорректное имя',
                    patronymic: false,
                    patronymicText: 'Введено некорректное отчество',
                    email: false,
                    emailText: 'Введен некорректный e-mail',
                    phone: false,
                    phoneText: 'Введен некорректный телефон',
                    agree2: false,
                    agree2Text: 'Необходимо Ваше согласие'
                }
            },
            topicInput: '',
            topicDropdown: false,
            f_file: null,
            f_file_label: 'Изображение'

        }
        this.info3Show = this.info3Show.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }
    info3Show = () => {
        this.modals.info3Show();
    }
    renderError = (form, field) => {
        switch (form) {
            case 'reg':
                switch (field) {
                    case 'surname':
                        if (this.state.errors.reg.surname === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.surname = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.surnameText}
                                </div>
                            )
                        }
                        break;
                    case 'name':
                        if (this.state.errors.reg.name === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.name = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.nameText}
                                </div>
                            )
                        }
                        break;
                    case 'patronymic':
                        if (this.state.errors.reg.patronymic === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.patronymic = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.patronymicText}
                                </div>
                            )
                        }
                        break;
                    case 'email':
                        if (this.state.errors.reg.email === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.email = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.emailText}
                                </div>
                            )
                        }
                        break;
                    case 'phone':
                        if (this.state.errors.reg.phone === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.phone = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.phoneText}
                                </div>
                            )
                        }
                        break;
                    case 'agree2':
                        if (this.state.errors.reg.agree2 === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.agree2 = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.agree2Text}
                                </div>
                            )
                        }
                        break;
                    case 'captch':
                        if (this.state.errors.reg.captch === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.captch = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.captchText}
                                </div>
                            )
                        }
                        break;
                }
                break;
        }
    }
    submitReg = async () => {

        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.reg.email === '') {
            errors.reg.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.reg.email)) {
                errors.reg.email = true;
                submit_enable = false;
            } else {
                errors.reg.email = false;
            }
        }

        if (this.state.data.reg.surname === '') {
            errors.reg.surname = true;
            submit_enable = false;
        } else {
            errors.reg.surname = false;
        }
        if (this.state.data.reg.name === '') {
            errors.reg.name = true;
            submit_enable = false;
        } else {
            errors.reg.name = false;
        }

        if (this.state.data.reg.phone === '') {
            errors.reg.phone = true;
            submit_enable = false;
        } else {
            errors.reg.phone = false;
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.reg.email);
            formData.append("surname", this.state.data.reg.surname);
            formData.append("name", this.state.data.reg.name);
            formData.append("patronymic", this.state.data.reg.patronymic);
            formData.append("phone", this.state.data.reg.phone);
            if (this.state.data.reg.agree2 === false) {
                formData.append("delivery", 0);
            } else {
                formData.append("delivery", 1);
            }

            await fetch(constants.API_URL + '/signup', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info3Show();
                }

                if (response.error === 1) {
                    if (response.message.pass !== undefined) {
                        errors.reg.pass1 = true;
                        errors.reg.pass1Text = response.message.pass[0];
                    }

                    if (response.message.login !== undefined) {
                        errors.reg.email = true;
                        errors.reg.emailText = response.message.login[0];
                    }

                    if (response.message.phone !== undefined) {
                        errors.reg.phone = true;
                        errors.reg.phoneText = response.message.phone[0];
                    }

                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }

    }


    render() {
        return (
            <div>
                <div className="section-login-reg section-reg">
                    <div className="section-login-reg-left">
                        <Link to="/" className="login-logo">
                            <img src={logoHeader} />
                        </Link>
                        <div className="section-title">Регистрация</div>
                        <div>* — поля, обязательные для заполнения</div>
                        <div className="form__list">
                            <div className="row">

                                <div className="col-d-6 col-m-12 form__col">
                                    <div className={'form__item ' + ((this.state.data.reg.nameFocus === true || this.state.data.reg.name !== '') ? 'form-focused' : '')}>

                                        <div className={['form__field ', ((this.state.errors.reg.name === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.nameInput.focus();
                                            }}>
                                                Имя*
                                                        </div>
                                            <input type="name" placeholder="Алексей" ref={(name) => { this.nameInput = name; }} value={this.state.data.reg.name} onBlur={() => {
                                                let data = this.state.data;
                                                data.reg.nameFocus = false;
                                                this.setState({ data: data });
                                            }} onFocus={() => {
                                                let data = this.state.data;
                                                let errors = this.state.errors;
                                                data.reg.nameFocus = true;
                                                errors.reg.name = false;
                                                this.setState({
                                                    data: data,
                                                    errors: errors
                                                });
                                            }} onChange={(name) => {
                                                let data = this.state.data;
                                                data.reg.name = name.target.value;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('reg', 'name')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-6 col-m-12 form__col">
                                    <div className={'form__item ' + ((this.state.data.reg.patronymicFocus === true || this.state.data.reg.patronymic !== '') ? 'form-focused' : '')}>

                                        <div className={['form__field ', ((this.state.errors.reg.patronymic === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.patronymicInput.focus();
                                            }}>
                                                Отчество
                                                        </div>
                                            <input type="patronymic" placeholder="Петрович" ref={(patronymic) => { this.patronymicInput = patronymic; }} value={this.state.data.reg.patronymic} onBlur={() => {
                                                let data = this.state.data;
                                                data.reg.patronymicFocus = false;
                                                this.setState({ data: data });
                                            }} onFocus={() => {
                                                let data = this.state.data;
                                                let errors = this.state.errors;
                                                data.reg.patronymicFocus = true;
                                                errors.reg.patronymic = false;
                                                this.setState({
                                                    data: data,
                                                    errors: errors
                                                });
                                                }} onChange={(patronymic) => {
                                                let data = this.state.data;
                                                data.reg.patronymic = patronymic.target.value;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('reg', 'patronymic')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-6 col-m-12 form__col">
                                    <div className={'form__item ' + ((this.state.data.reg.surnameFocus === true || this.state.data.reg.surname !== '') ? 'form-focused' : '')}>

                                        <div className={['form__field ', ((this.state.errors.reg.surname === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.surnameInput.focus();
                                            }}>
                                                Фамилия*
                                                        </div>
                                            <input type="surname" placeholder="Иванов" ref={(surname) => { this.surnameInput = surname; }} value={this.state.data.reg.surname} onBlur={() => {
                                                let data = this.state.data;
                                                data.reg.surnameFocus = false;
                                                this.setState({ data: data });
                                            }} onFocus={() => {
                                                let data = this.state.data;
                                                let errors = this.state.errors;
                                                data.reg.surnameFocus = true;
                                                errors.reg.surname = false;
                                                this.setState({
                                                    data: data,
                                                    errors: errors
                                                });
                                            }} onChange={(surname) => {
                                                let data = this.state.data;
                                                data.reg.surname = surname.target.value;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('reg', 'surname')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-6 col-m-12 form__col">
                                    <div className={'form__item ' + ((this.state.data.reg.phoneFocus === true || this.state.data.reg.phone !== '') ? 'form-focused' : '')}>

                                        <div className={['form__field ', ((this.state.errors.reg.phone === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.phone2Input.querySelector('input').focus();
                                            }}>
                                                Телефон*
                                                        </div>
                                            <div ref={(phone2) => { this.phone2Input = phone2; }}>
                                                <InputMask type="phone" mask="+7(999)999-99-99" placeholder="+7 - _ _ _ - _ _ _ - _ _ - _ _" alwaysShowMask={false} value={this.state.data.reg.phone} onBlur={() => {
                                                    let data = this.state.data;
                                                    data.reg.phoneFocus = false;
                                                    this.setState({ data: data });
                                                }} onFocus={() => {
                                                    let data = this.state.data;
                                                    let errors = this.state.errors;
                                                    data.reg.phoneFocus = true;
                                                    errors.reg.phone = false;
                                                    this.setState({
                                                        data: data,
                                                        errors: errors
                                                    });
                                                }} onChange={(phone) => {
                                                    let data = this.state.data;
                                                    data.reg.phone = phone.target.value;
                                                    this.setState({ data: data });
                                                }} />
                                                {
                                                    this.renderError('reg', 'phone')
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-12 col-m-12 form__col">
                                    <div className={'form__item ' + ((this.state.data.reg.emailFocus === true || this.state.data.reg.email !== '') ? 'form-focused' : '')}>
                                        <div className={['form__field', ((this.state.errors.reg.email === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.email3Input.focus();
                                            }}>
                                                Электронная почта*
                                                        </div>
                                            <input placeholder="e-mailexample@mail.com" ref={(email3) => { this.email3Input = email3; }} type="text" autoComplete="username" onFocus={() => {
                                                let data = this.state.data;
                                                data.reg.emailFocus = true;
                                                let errors = this.state.errors;
                                                errors.reg.email = false;
                                                this.setState({ errors: errors, data: data });
                                            }} onChange={(email) => {
                                                let data = this.state.data;
                                                data.reg.email = email.target.value;
                                                this.setState({ data: data });
                                            }} onBlur={() => {
                                                let data = this.state.data;
                                                data.reg.emailFocus = false;
                                                this.setState({ data: data });
                                            }} value={this.state.data.reg.email} />
                                            {
                                                this.renderError('reg', 'email')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-12 col-m-12 form__col">
                                    <div className="checkbox__list">
                                        <div className={'checkbox__item d-flex ' + ((this.state.errors.reg.agree2 === true) ? 'has-error' : '') + ((this.state.data.reg.agree2 === true) ? 'active' : '')}>
                                            <label>
                                                <input type="checkbox" name="agree2" onChange={(agree2) => {
                                                    let data = this.state.data;
                                                    let errors = this.state.errors;
                                                    data.reg.agree2 = agree2.target.checked;
                                                    errors.reg.agree2 = false;
                                                    this.setState({ data: data });
                                                    this.setState({ errors: errors });
                                                }} />
                                                <div className="checkbox__decor"></div>
                                                <div className="checkbox__title">Даю согласие на получение информационной или рекламной рассылки</div>
                                            </label>
                                            {
                                                this.renderError('reg', 'agree2')
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-12 col-m-12 form__col">
                                    <div className="form__item form__item-actions d-flex align-items-center justify-content-start">
                                        <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                            <div onClick={() => {
                                                this.submitReg();
                                            }} className="btn__custom btn-blue size-middle btn__custom-dark d-flex align-items-center justify-content-center">

                                                <div className={'loader ' + ((this.state.loading === true) ? 'active' : '')}><img src={loader} /></div>
                                                <span>Зарегистрироваться</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-12 col-m-12 form__col text-center">
                                    <div className="form__link">
                                        <p>Уже есть аккаунт? <a href="/login">Войдите</a></p>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                    <div className="section-login-reg-img">
                        <span className="logo-guarantee"></span>
                        <img src={minBgDog} className="minBgDog" />
                    </div>
                </div>
                <Modals runWidget={this.runWidget} ref={instance => { this.modals = instance; }} />
            </div>

        );
    }
}
