import React from 'react'
import { Link } from 'react-router-dom';
import logoHeader from '../assets/img/hils_new_header_logo.png';
import * as constants from '../constants';

export default class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nav: false
        };
    }


    componentDidMount() {
        /*console.log(localStorage.getItem('auth_key'));*/
    }

    componentWillUnmount() {

    }


    renderHeaderRight = () => {
        if (localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) {
            return (
                <div className="header__right-logged-in">
                    <Link to="/applications" className="logged-name">
                        {localStorage.getItem('auth_name')}
                    </Link>
                    <ul className='navLogged '>
                        {/*<li className='cabinet' onClick={() => {
                            this.props.showChangeModal();
                        }}>
                            <span className="">Сменить пароль</span>
                        </li>*/}
                        <li className="btn-logout">
                            <span onClick={() => {
                                this.logout()
                            }}>Выход</span>
                        </li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div className="box-header-btn">
                    <Link to="/login">Личный кабинет</Link>
                </div>
            )
        }
    }
    renderHeaderRightMobile = () => {
        if (localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) {
            return (
                <div className="header__right-logged-in header__right-loggin">
                    <ul className='navLogged '>
                        <li className="btn-logout">
                            <span onClick={() => {
                                this.logout()
                            }}>Выйти из аккаунта</span>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    logout = async() => {
        let errors = this.state.errors;
        let submit_enable = true;

                    localStorage.removeItem('auth_key');
                    localStorage.removeItem('auth_name');
                    localStorage.removeItem('threeStep');
                    window.location.replace('/');
        /*if(submit_enable === true){
            this.setState({
                loading: true
            });
            await fetch(constants.API_URL + '/logout', {
                method: "POST",
                headers:{
                    'X-Auth-Token': localStorage.getItem('auth_key')
                }
            }).then((response) => response.json()).then((response) => {

                if(response.error === 0){
                }

                this.setState({
                    loading: false
                });
            });
        }*/

    }

    render() {
        return (
            <div className="header">
                <div className="container d-flex align-items-center">
                    <div className="logo__box d-flex align-items-center">
                        <Link to="/" className="logo">
                        <img src={logoHeader} />
                        </Link>
                    </div>


                    <div className="hidden-des header__btn">
                        <div className={"hamburger " + ((this.state.nav === true) ? "-active" : "")}><div className="hamburger-box" onClick={() => {
                            this.setState({
                                nav: !this.state.nav
                            })
                        }}><div className="hamburger-inner"></div></div></div>
                    </div>
                    <div className={"header__right " + ((this.state.nav === true) ? "active" : "")}>
                        <ul id="menu">
                            <li className={(this.props.page === 'applicationReturn') ? 'active' : ''}>
                                {[(localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) ? <Link key="1" to="/applications-return" onClick={() => {
                                    localStorage.removeItem('ApplicationStep');
                                    localStorage.removeItem('ApplicationId');
                                    localStorage.removeItem('threeStep');
                                }}>Оформить возврат</Link> : <Link to="/login" key="2" onClick={() => {
                                    localStorage.removeItem('ApplicationStep');
                                    localStorage.removeItem('ApplicationId');
                                    localStorage.removeItem('threeStep');
                                }}>Оформить возврат</Link>]}
                            </li>
                            <li className={(this.props.page === 'faq') ? 'active' : ''}>
                                <a href="/#faq"><span>FAQ</span></a>
                            </li>
                            <li className={(this.props.page === 'feeding-recommendations') ? 'active' : ''}>
                                <Link to="/feeding-recommendations"><span>Советы по питанию</span></Link>
                            </li>
                            <li className={(this.props.page === 'winners') ? 'active' : ''}>
                                <a href="Правила_Hills_гарантия_качества.pdf" target="_blank"><span>Правила акции</span></a>
                            </li>
                        </ul>
                        {this.renderHeaderRightMobile()}
                    </div>
                        {this.renderHeaderRight()}
                </div>
            </div>

        );
    }
}
