import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import Modals from "./Modals";
import howWorks0 from "../assets/img/how-works0.svg";
import howWorks1 from "../assets/img/how-works1.svg";
import howWorks2 from "../assets/img/how-works2.svg";
import howWorks3 from "../assets/img/how-works3.svg";
import howWorks4 from "../assets/img/how-works4.svg";
import "../assets/css/App.scss";

let btnMore = true;
function doSomething(scroll_pos) {
    // Делаем что-нибудь с позицией скролла
    /*console.log(scroll_pos);*/
    if (scroll_pos > 20) {

        btnMore = false;
    } else {
        btnMore = true;
    }
}
export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faq: 0,
            btnMore: true
        }
        this.btnFeedbackShow = this.btnFeedbackShow.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.info5Show = this.info5Show.bind(this);
    }
    btnFeedbackShow = () => {
        this.modals.feedbackShow();
    }
    info5Show = () => {
        this.modals.info5Show();
    }
    componentDidUpdate = () => {
    }
    componentDidMount() {

    /*window.addEventListener('scroll', this.handleScroll, true);*/
    window.addEventListener('scroll', this.handleScroll, false);

        /*window.addEventListener('scroll', function () {
            last_known_scroll_position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function () {
                    doSomething(last_known_scroll_position);
                    ticking = false;
                });

                ticking = true;
            }
        });*/
    }
    doSomething = (scroll_pos) => {
        // Делаем что-нибудь с позицией скролла
        //console.log(scroll_pos);
    }
    componentWillUnmount() {
        /*window.removeEventListener('scroll', this.handleScroll);*/
    }
    nextSlide = () => {
        const el = document.getElementById('how-works');
        el.scrollIntoView({ behavior: "smooth" });
    }


    handleScroll = () => {
        let last_known_scroll_position = 0;
        let ticking = false;
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
            window.requestAnimationFrame(function () {
                doSomething(last_known_scroll_position);
                ticking = false;
            });

            ticking = true;
        }
        /*var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        console.log(scrollTop);
        setTimeout(() => {
            if (scrollTop > 20) {

                let btnMore = this.state.btnMore;
                btnMore = false;
                this.setState({
                    btnMore: btnMore
                });
                console.log(this.state.btnMore);
            } else {
                let btnMore = this.state.btnMore;
                btnMore = true;
                this.setState({
                    btnMore: btnMore
                });
                console.log(this.state.btnMore);
            }
        }, 1000);*/
    };

    btnReturn = () => {
        return (
            <Link to="/" className="btn-return">Оформить заявку на возврат</Link>
        )
    }
    toggleFaq = (numb) => {
        if (this.state.faq === numb) {
            this.setState({
                faq: 0
            })
        } else {
            this.setState({
                faq: numb
            })
        }
    }

    render() {
        return (
            <div className="app" onScroll={this.handleScroll}>
                {/*<div className="app" onScroll={this.listenScrollEvent()}>*/}
                <Header info5Show={this.info5Show}/>
                <div className="section-bann">
                    <div className="container">
                        <div className="slogan">
                            <h1>
                                <span className="text1">Мы&nbsp;гарантируем качество, консистенцию и&nbsp;вкус наших рационов</span>
                            </h1>
                            <p className="text text4">Если Вы&nbsp;или Ваш питомец не&nbsp;удовлетворены качеством, консистенцией или вкусом, мы&nbsp;вернем Вам деньги.</p>
                            {[(localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) ? <Link to="/applications-return" className="btn-return text-regular" key="1">Оформить заявку</Link> : <Link to="/login" className="btn-return text-regular" key="1">Оформить заявку</Link>]}
                        </div>
                        {[(this.state.btnMore) ? <Link to="#how-works" className="more" key="1" onClick={() => {
                            this.nextSlide()
                        }} >Подробнее</Link> : '' ]}

                        <span className="logo-guarantee"></span>
                    </div>
                </div>
                <div className="section how-works" id="how-works">
                    <div className="container">
                        <div className="section-title">Как это работает?</div>
                        <p>Средства будут возвращены в&nbsp;том случае, если корм не&nbsp;подошел Вашему <br />питомцу и&nbsp;содержит в&nbsp;упаковке не&nbsp;менее&nbsp;70% веса, указанного на&nbsp;упаковке продукта.</p>
                        <div className="how-works-items">
                            <div className="how-works-item">
                                <div className="how-works-item-img"><img src={howWorks0} /></div>
                                <div className="how-works-item-title">Зарегистрируйтесь</div>
                                <p>
                                {[(localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) ? <Link to="/applications-return" key="1">на сайте</Link> : <Link to="/login" key="1">на сайте</Link>]}
                                </p>
                            </div>
                            <div className="how-works-item">
                                <div className="how-works-item-img"><img src={howWorks1} /></div>
                                <div className="how-works-item-title">Загрузите чек и&nbsp;заполните заявку </div>
                                <p>на возврат корма</p>
                            </div>
                            <div className="how-works-item">
                                <div className="how-works-item-img"><img src={howWorks2} /></div>
                                <div className="how-works-item-title">Дождитесь обработки заявки</div>
                                <p>Мы сообщим Вам статус в&nbsp;течение 72 часов</p>
                            </div>
                            <div className="how-works-item">
                                <div className="how-works-item-img"><img src={howWorks3} /></div>
                                <div className="how-works-item-title">Отправьте корм</div>
                                <p>Отправьте нам упаковку корма Почтой России. Для возврата денег нужно, чтобы упаковка была заполнена не&nbsp;менее чем на&nbsp;70%</p>
                            </div>
                            <div className="how-works-item">
                                <div className="how-works-item-img"><img src={howWorks4} /></div>
                                <div className="how-works-item-title">Получите деньги</div>
                                <p>за корм Hill's</p>
                            </div>
                        </div>
                        <div className="col-d-12 text-center">
                            {[(localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) ? <Link to="/applications-return" className="btn-return" key="1">Оформить заявку на возврат</Link> : <Link to="/login" className="btn-return" key="1">Оформить заявку на возврат</Link>]}
                        </div>
                    </div>
                </div>
                <div className="section faq" id="faq">
                    <div className="container">
                        <div className="section-title">FAQ</div>
                        <p>Ответы на наиболее часто задаваемые вопросы.</p>
                        <div className="faq__list">
                            <div onClick={() => {
                                this.toggleFaq(1)
                            }} className={'faq__item ' + ((this.state.faq === 1) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Когда я&nbsp;могу получить возврат денег?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        В&nbsp;случае соблюдения условий программы возврат денежных средств, потраченных на&nbsp;покупку и&nbsp;пересылку корма Hill’s, осуществляется в&nbsp;течение 7&nbsp;(семи) банковских дней с&nbsp;момента проверки содержимого посылки на&nbsp;соответствие требованиям.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(3)
                            }} className={'faq__item ' + ((this.state.faq === 3) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Какая продукция Hill&rsquo;s участвует в&nbsp;программе 100% гарантия?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Все корма для кошек и&nbsp;собак Hill&rsquo;s Prescription Diet и&nbsp;Hill&rsquo;s Science Plan
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(5)
                            }} className={'faq__item ' + ((this.state.faq === 5) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    В&nbsp;каком случае я&nbsp;могу вернуть деньги за&nbsp;корм?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Возврат денег за&nbsp;корм возможен в&nbsp;случае, если&nbsp;Вы не&nbsp;удовлетворены качеством, консистенцией или корм не&nbsp;подошел питомцу, и&nbsp;при обязательном наличии чека, подтверждающего покупку корма. Возвращаемый корм должен содержать в&nbsp;упаковке продукта не&nbsp;менее 70 % от&nbsp;общего веса, указанного на&nbsp;упаковке.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(7)
                            }} className={'faq__item ' + ((this.state.faq === 7) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Если у&nbsp;меня нет чека, что мне делать?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        К&nbsp;сожалению, в&nbsp;данном случае мы не&nbsp;сможем компенсировать вам сумму, потраченную на&nbsp;покупку корма Hill&rsquo;s.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(9)
                            }} className={'faq__item ' + ((this.state.faq === 9) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Как я&nbsp;узнаю о&nbsp;том, что моя заявка одобрена?
                                                        </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        На&nbsp;электронный адрес, указанный Вами при&nbsp;регистрации на&nbsp;сайте, поступит электронное письмо со&nbsp;статусом заявки, а&nbsp;также вы увидите статус заявки в&nbsp;личном кабинете на&nbsp;сайте getbackhills.ru.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(10)
                            }} className={'faq__item ' + ((this.state.faq === 10) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Какую сумму я&nbsp;получу?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Вы&nbsp;можете получить 100% от&nbsp;стоимости возвращенного корма согласно сумме в&nbsp;чеке, а&nbsp;также стоимость отправки вскрытой упаковки корма Почтой России.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(11)
                            }} className={'faq__item ' + ((this.state.faq === 11) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Каким способом я&nbsp;могу получить деньги?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Денежные средства зачисляются на&nbsp;банковские реквизиты, оформленные на&nbsp;Ваше имя (расчетный счет получателя, БИК&nbsp;банка, ФИО&nbsp;получателя).
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(13)
                            }} className={'faq__item ' + ((this.state.faq === 13) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Сколько раз можно использовать один чек?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Один чек может быть использован для&nbsp;возврата денежных средств не&nbsp;более одного раза.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(15)
                            }} className={'faq__item ' + ((this.state.faq === 15) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Надо&nbsp;ли платить налоги с&nbsp;возврата денег?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Платить налоги с&nbsp;возврата денег за&nbsp;товар не&nbsp;нужно, так как возвращаемые денежные средства не&nbsp;являются доходом.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(17)
                            }} className={'faq__item ' + ((this.state.faq === 17) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Как и&nbsp;куда отправлять корм, качество которого меня не&nbsp;устраивает?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Посылка должна быть отправлена Почтой России простым почтовым отправлением на&nbsp;следующий адрес: 119002, Москва, Арбат&nbsp;ул., 47/23, а/я 23, получатель: ООО&nbsp;«АМГ».
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(18)
                            }} className={'faq__item ' + ((this.state.faq === 18) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Отправка корма для&nbsp;возврата денег бесплатная?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Мы&nbsp;возместим расходы за&nbsp;пересылку упаковки корма, качество которого Вас не&nbsp;устроило, оформленного простым почтовым отправлением только при&nbsp;наличии чека. Мы не&nbsp;сможем компенсировать Вам расходы за&nbsp;пересылку, оформленную не&nbsp;простым почтовым отправлением.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(22)
                            }} className={'faq__item ' + ((this.state.faq === 22) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Почему мой чек не&nbsp;одобрили? Что мне делать?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Мы проводим обязательную модерацию чеков в&nbsp;срок до&nbsp;72&nbsp;часов на&nbsp;достоверность, валидность, а&nbsp;также на&nbsp;соответствие условиям программы. Если Ваш чек не&nbsp;прошел проверку, он&nbsp;не&nbsp;соответствует одному или нескольким требованиям <a href="/Правила_Hills_гарантия_качества.pdf" target="_blank">правил программы</a>. Вы&nbsp;можете повторно загрузить корректный чек в&nbsp;существующую заявку.<br />
                                        Фотография или скан чека должны соответствовать следующим техническим требованиям: формат JPEG, Gif, Png, размер не&nbsp;более 10&nbsp;Мб и QR-код должен быть разборчивым.
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => {
                                this.toggleFaq(25)
                            }} className={'faq__item ' + ((this.state.faq === 25) ? 'active' : '')}>
                                <div className="faq__item-header">
                                    Что делать, если в&nbsp;этом списке нет ответов на&nbsp;возникшие вопросы?
                                </div>
                                <div className={'faq__item-answer fadeIn animated'}>
                                    <div className="faq__item-text">
                                        Вы&nbsp;можете задать интересующий Вас вопрос через <span className="faq__item-span" onClick={() => this.btnFeedbackShow()}>форму обратной связи</span> на&nbsp;сайте.<br />
                                        Подробнее с&nbsp;<a href="/Правила_Hills_гарантия_качества.pdf" target="_blank">правилами программы</a>&nbsp;Вы можете ознакомиться, скачав полную версию правил в&nbsp;разделе &laquo;правила акции&raquo; на&nbsp;главной странице сайта.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section still-have-questionsn">
                    <div className="container">
                        <div className="section-title">Остались вопросы?</div>
                        <p>Задайте свой вопрос, и мы обязательно&nbsp;Вам&nbsp;поможем</p>
                        <div className="btn-ask-question" onClick={() => this.btnFeedbackShow()}>Задать вопрос</div>
                    </div>
                </div>
                <Footer page={"home"}  handleScroll={this.handleScroll} btnFeedbackShow={this.btnFeedbackShow} info5Show={this.info5Show}  />
                <Modals runWidget={this.runWidget} ref={instance => { this.modals = instance; }} />
            </div>
        );
    }
}
