import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import File from "./File";
import Footer from "./Footer";
import SlideMenu from "./SlideMenu";
import Modals from "./Modals";
import InputMask from 'react-input-mask';
import { AddressSuggestions } from 'react-dadata';
import DadataSuggestions from 'react-dadata-suggestions';
import art1 from "../assets/img/art1.png";
import art2 from "../assets/img/art2.png";
import art3 from "../assets/img/art3.png";
import data1 from "../assets/img/data1.png";
import data2 from "../assets/img/data2.png";
import data3 from "../assets/img/data3.png";
import data4 from "../assets/img/data4.png";
import "../assets/css/App.scss";
import * as constants from '../constants';

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            submit: false,
            loading: false,
            passType: 'password',
            step: 1,
            NumberStep: 1,
            widjetActive: false,
            threeStep: false,
            applicationId: null,
            data: {
                appRet: {
                    topic: null,
                    topicFocus: false,
                    foodOrMoney: null,
                    moreFeed: null,
                    feedArticle: '',
                    feedArticleFocus: false,
                    batchNumber: '',
                    batchNumberFocus: false,
                    feedCost: '',
                    feedCostFocus: false,
                    outletName: '',
                    outletNameFocus: false,
                    outletAddress: '',
                    outletAddressFocus: false,
                    onFilesChange: [],
                    btnNoCheck: false,
                    noCheck: null,
                    problem: '',
                    problemFocus: false,
                    transferNewFeed: '',
                    transferNewFeedFocus: false,
                    trackNumber: null,
                    trackNumberFocus: false,
                    responseCompany: null,
                    responseCompanyFocus: false,
                    choice: null,
                    choiceFocus: false,
                    step: null,
                    returnPhone: '',
                    returnPhoneFocus: false,
                    returnWallet: '',
                    returnWalletFocus: false
                }
            },
            errors: {
                appRet: {
                    topic: false,
                    topicText: 'Не выбрана причина обращения',
                    feedArticle: false,
                    feedArticleText: 'Введен некорректный артикул',
                    batchNumber: false,
                    batchNumberText: 'Введите дата-код не менее 19 символов',
                    feedCost: false,
                    feedCostText: 'Введена некорректная стоимость',
                    outletName: false,
                    outletNameText: 'Введено некорректное название торговой точки',
                    outletAddress: false,
                    outletAddressText: 'Введен некорректный адрес',
                    onFilesChange: false,
                    onFilesChangeText: 'Фотографии не загружены',
                    onFilesChange2: false,
                    onFilesChange2Text: 'Фотографии не загружены',
                    problem: false,
                    problemText: 'Введено некорректное описание проблемы',
                    validate3: false,
                    transferNewFeed: false,
                    transferNewFeedText: 'Введен некорректный артикул',
                    trackNumber: false,
                    trackNumberText: 'Невыбран способ получения денег',
                    responseCompany: false,
                    responseCompanyText: 'Невыбран способ получения денег',
                    choice: false,
                    choiceText: 'Невыбран способ получения денег',
                    returnPhone: false,
                    returnPhoneText: 'Введен некорректный телефон',
                    returnWallet: false,
                    returnWalletText: 'Введен некорректный номер кошелька'
                }
            },
            topicInput: 'Выберите причину',
            topicDropdown: false,
            f_file: null,
            f_file_label: 'Изображение'
        }
        this.info11Show = this.info11Show.bind(this);
        this.info9Show = this.info9Show.bind(this);
        this.info12Show = this.info12Show.bind(this);
        this.info5Show = this.info5Show.bind(this);
        this.widget = this.widget.bind(this);
        this.btnFeedbackShow = this.btnFeedbackShow.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    btnFeedbackShow = () => {
        this.modals.feedbackShow();
    }
    info5Show = () => {
        this.modals.info5Show();
    }
    componentDidMount() {
        //this.info5Show();
        setTimeout(() => {
            document.getElementById("anketa__content").setAttribute("style","opacity:1; -moz-opacity:1; filter:alpha(opacity=100)");
        }, 300);
        const el3 = document.getElementById('menu');
        el3.scrollIntoView();

        if (localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) {
        } else {
            window.location.replace('/');
        }
        if (localStorage.getItem('ApplicationStep') && localStorage.getItem('ApplicationStep') !== null && localStorage.getItem('ApplicationStep') !== undefined && localStorage.getItem('ApplicationStep') !== '') {
            var threeStep = JSON.parse((localStorage.getItem('threeStep') === 'true' ) ? true : false)
            console.log('1');
            this.setState({
                step: Number(localStorage.getItem('ApplicationStep')),
                NumberStep: Number(localStorage.getItem('ApplicationStep'))+1,
                applicationId: Number(localStorage.getItem('ApplicationId')),
                threeStep: threeStep
            });
            let data = this.state.data;
            data.appRet.feedArticle = (localStorage.getItem('article'));
            data.appRet.batchNumber = (localStorage.getItem('batchNumber'));

            let x = (localStorage.getItem('cost'));
            if (x == '0')
            {
                data.appRet.feedCost = '';
            }
            else
            {
                data.appRet.feedCost = x;
            }
            x = (localStorage.getItem('shop'));
            if (x == 'null')
            {
                data.appRet.outletName = '';
            }
            else
            {
                data.appRet.outletName = x;
            }

            data.appRet.address = (localStorage.getItem('address'));
            data.appRet.problem = (localStorage.getItem('problem'));
            x = (localStorage.getItem('problem'));
            if (x == 'null')
            {
                data.appRet.problem = '';
            }
            else
            {
                data.appRet.problem = x;
            }
            x = (localStorage.getItem('comment'));
            if (x == 'null')
            {
                data.appRet.transferNewFeed = '';
            }
            else
            {
                data.appRet.transferNewFeed = x;
            }

        } else {
            console.log('2');
            localStorage.removeItem('ApplicationStep');
            localStorage.removeItem('ApplicationId');
            localStorage.removeItem('threeStep');
            localStorage.removeItem('article');
            localStorage.removeItem('batchNumber');
            localStorage.removeItem('cost');
            localStorage.removeItem('shop');
            localStorage.removeItem('address');
            localStorage.removeItem('problem');
            localStorage.removeItem('comment');

            /*let data = this.state.data;
            data.appRet.foodOrMoney = 1;
            this.setState({ data: data });
            this.nextTab('2');*/
        }
        console.log(this.props);
        console.log(this.props.location.id);
        console.log(localStorage.getItem('ApplicationStep'));
        if ((!!this.props.location.step) && (!!this.props.location.id) && (!!localStorage.getItem('ApplicationStep'))) {
            console.log('applacationReturn');
            localStorage.removeItem('ApplicationStep');
            localStorage.removeItem('ApplicationId');
            localStorage.removeItem('threeStep');
            localStorage.removeItem('article');
            localStorage.removeItem('batchNumber');
            localStorage.removeItem('cost');
            localStorage.removeItem('shop');
            localStorage.removeItem('address');
            localStorage.removeItem('problem');
            localStorage.removeItem('comment');

            var step = this.props.location.step;
            console.log(this.props.location.needCheckUpload);
            var applicationId = this.props.location.id
            var article = ((this.props.location.article !== null ) ? this.props.location.article : '')
            var batchNumber = this.props.location.batchNumber;
            var cost = this.props.location.cost;
            var shop = this.props.location.shop;
            var address = this.props.location.address;
            var problem = this.props.location.problem;
            var comment = this.props.location.comment;

            this.setState({
                step: step,
                NumberStep: step+1,
                applicationId: applicationId,
                threeStep: (((article !== null) && (article !== '') ) ? true : false)
            });
            let data = this.state.data;
            data.appRet.feedArticle = article;
            data.appRet.batchNumber = batchNumber;
            data.appRet.feedCost = cost;
            data.appRet.problem = problem;
            data.appRet.transferNewFeed = comment;
            data.appRet.outletName = shop;

            localStorage.setItem('ApplicationStep', step);
            localStorage.setItem('ApplicationId', applicationId);
            localStorage.setItem('article', article);
            localStorage.setItem('batchNumber', batchNumber);
            localStorage.setItem('cost', cost);
            localStorage.setItem('shop', shop);
            localStorage.setItem('address', address);
            localStorage.setItem('problem', problem);
            localStorage.setItem('comment', comment);

            console.log(article);
            var threeStep = JSON.parse(((article !== null) && (article !== '')) ? true : false)
            localStorage.setItem('threeStep', threeStep);

        } else {
            if (!localStorage.getItem('ApplicationStep')) {
                console.log('3');
                let data = this.state.data;
                data.appRet.foodOrMoney = 1;
                this.setState({ data: data });
                this.nextTab('2');
            }
        }
        /*if ((window.location.href.split('applicationsStep=')[1])) {
            var u = (new URL(window.location.href)).searchParams;
            var step = this.state.step;
            var applicationId = this.state.applicationId;
            step = Number(u.get("applicationsStep"));
            applicationId = Number(u.get("applicationsId"));

            console.log(applicationId);
            this.setState({
                step: step,
                applicationId: applicationId
            });
            console.log(this.state.step);
            console.log(this.state.applicationId);
        }
        const paramsString = window.location.href;
        const searchParams = new URLSearchParams(paramsString);
        searchParams.set('applicationsStep', this.state.step);
        searchParams.toString(); // return modified string.
        console.log(searchParams.toString()); // return modified string.*/
        /*setTimeout(() => {
            this.widget();
        }, 1000);*/
    }
    widget = () => {

        //console.log(localStorage.getItem('auth_userUuid'));
        console.log('needCheckUpload');
        const widgetParams = (qw) => {
            return {
                api: 'https://api.apmcheck.ru',
                apiKey: '37a2303b-8f88-4fc1-9c00-d3f88e7852e6',
                meta: { 'utm': this.state.applicationId },
                channel: 'test-widget',
                userUuid: localStorage.getItem('auth_userUuid'),
                maxPhotoSizeInMiB: 10,
                styles: {
                    mainButton: 'custom-main-button',
                    manualQrButton: 'custom-manual-qr-button',
                    uploadPhotosButton: 'custom-upload-button',
                    submitManualQrButton: 'custom-submit-manual-qr-button',
                    addPhotoButton: 'custom-add-photo-button',
                    submitPhotosButton: 'custom-submit-photos-button'
                },
                i18n: {
                    labels: {
                        mainButton: 'сканировать qr-код чека',
                        manualQrButton: 'ввести данные вручную',
                        uploadPhotosButton: 'загрузить фото QR-кода с чека',
                        submitManualQrButton: 'отправить',
                        addPhotoButton: 'Загрузить фото',
                        submitPhotosButton: 'отправить'
                    },
                    screens: {
                        scanQr: {
                            header: 'Загрузка чека<span>Сканирование</span>',
                            subheader: 'Наведите камеру на QR-код'
                        },
                        manualInput: {
                            header: 'Загрузка чека<span>Ручной ввод</span>',
                            subheader: 'Введите данные с чека'
                        },
                        cameraNotAvailable: {
                            header: 'Загрузка чека',
                            subheader: 'Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства.<br><br>Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную'
                        },
                        qrParsed: {
                            header: 'Благодарим!',
                            subheader: 'Ваш чек принят в обработку.'
                        },
                        uploadFiles: {
                            header: 'Загрузка чека',
                            subheader: 'Добавьте фото чека (до 5 частей)',
                            fileTooLargeError: 'Файл больше 10 МБ. Загрузите файл меньшего размера.'
                        },
                        sentReceiptFailed: {
                            header: 'Не удалось отправить чек!'
                        }
                    },
                    errors: {
                        "0002-0008": "Ой, что-то пошло не так."
                    }
                },
                callbacks: {
                    onReceiptSentSuccess: function (res) {

                        const formData = new FormData();
                        formData.append("step", 3);
                        formData.append("id", qw.state.applicationId);
                        formData.append("article", qw.state.data.appRet.feedArticle);
                        formData.append("code", qw.state.data.appRet.batchNumber);
                        formData.append("cost", qw.state.data.appRet.feedCost);
                        formData.append("shop", qw.state.data.appRet.outletName);
                        formData.append("address", qw.state.data.appRet.outletAddress);
                        formData.append("packImages", qw.file.state.fileList);
                        for (var x = 0; x < qw.file.state.fileList.length; x++) {
                            formData.append("packImages[]", qw.file.state.fileList[x]);
                        }
                        formData.append("packImages", qw.file.state.fileList2);
                        formData.append("problem", qw.state.data.appRet.problem);
                        formData.append("comment", qw.state.data.appRet.transferNewFeed);
                        qw.submitApplication(formData);
                    },
                    onReceiptSentError: function (res) {
                        let data = this.state.data;
                        data.appRet.noCheck = 0;
                        this.setState({ data: data });
                    }
                }

            }
        }
        window.qrWidget.init("apm-widget", widgetParams(this));

    }

    btnReturn = () => {
        return (
            <Link to="/" className="btn-return">Оформить заявку на возврат</Link>
        )
    }
    info11Show = (mText, mUrl) => {
        this.modals.info11Show(mText, mUrl);
    }
    info9Show = () => {
        this.modals.info9Show();
    }
    info12Show = () => {
        this.modals.info12Show();
    }
    handleScroll = () => {
        // setTimeout(() => {
        //     var hiddenElement = document.getElementsByClassName("has-error");
        //     hiddenElement[0].scrollIntoView({ block: "center", behavior: "smooth" });
        // }, 100);

    };
    renderError = (form, field) => {
        switch (form) {
            case 'appRet':
                switch (field) {
                    case 'topic':
                        if (this.state.errors.appRet.topic === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.topic = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.topicText}
                                </div>
                            )
                        }
                        break;
                    case 'feedArticle':
                        if (this.state.errors.appRet.feedArticle === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.feedArticle = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.feedArticleText}
                                </div>
                            )
                        }
                        break;
                    case 'email':
                        if (this.state.errors.reg.email === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.email = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.emailText}
                                </div>
                            )
                        }
                        break;
                    case 'phone':
                        if (this.state.errors.reg.phone === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.reg.phone = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.reg.phoneText}
                                </div>
                            )
                        }
                        break;
                    case 'onFilesChange':
                        if (this.state.errors.appRet.onFilesChange === true) {
                                return (
                                    <div className="error__message" onClick={() => {
                                        let errors = this.state.errors;
                                        errors.appRet.onFilesChange = false;
                                        this.setState({ errors: errors });
                                    }}>
                                        {this.state.errors.appRet.onFilesChangeText}
                                    </div>
                                )
                            }
                        break;
                    case 'onFilesChange2':
                        if (this.state.errors.appRet.onFilesChange2 === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.onFilesChange2 = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.onFilesChange2Text}
                                </div>
                            )
                        }
                        break;
                    case 'batchNumber':
                        if (this.state.errors.appRet.batchNumber === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.batchNumber = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.batchNumberText}
                                </div>
                            )
                        }
                        break;
                    case 'feedCost':
                        if (this.state.errors.appRet.feedCost === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.feedCost = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.feedCostText}
                                </div>
                            )
                        }
                        break;
                    case 'outletName':
                        if (this.state.errors.appRet.outletName === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.outletName = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.outletNameText}
                                </div>
                            )
                        }
                        break;
                    case 'outletAddress':
                        if (this.state.errors.appRet.outletAddress === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.outletAddress = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.outletAddressText}
                                </div>
                            )
                        }
                        break;
                    case 'returnPhone':
                        if (this.state.errors.appRet.returnPhone === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.returnPhone = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.returnPhoneText}
                                </div>
                            )
                        }
                        break;
                    case 'returnWallet':
                        if (this.state.errors.appRet.returnWallet === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.returnWallet = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.returnWalletText}
                                </div>
                            )
                        }
                        break;
                    case 'problem':
                        if (this.state.errors.appRet.problem === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.appRet.problem = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.appRet.problemText}
                                </div>
                            )
                        }
                        break;
                }
        }
    }
    nextTab = (form) => {
        let errors = this.state.errors;
        switch (form) {
            case '2':
                //Текущий этап
                let data = this.state.data;
                data.appRet.step = 1;
                this.setState({ data: data });
                //добавление к отправке
                const formData = new FormData();
                formData.append("step", 1);
                formData.append("type", this.state.data.appRet.foodOrMoney);
                this.submitApplication(formData);
                break;
            case '3':
                    if ((this.state.data.appRet.topic !== '') && (this.state.data.appRet.topic !== null)) {
                        if ((this.state.data.appRet.moreFeed === 2)) {
                            this.info9Show();
                        } else {
                            //Текущий этап
                            let data = this.state.data;
                            data.appRet.step = 2;
                            this.setState({ data: data });
                            //добавление к отправке
                            const formData = new FormData();
                            formData.append("step", 2);
                            formData.append("id", this.state.applicationId);
                            formData.append("reason", this.state.data.appRet.topic);
                            this.submitApplication(formData);
                        } 
                    }
                    else {
                        errors.appRet.topic = true;
                        this.handleScroll();
                    }
                break;
            case '32':
                    if ((this.state.data.appRet.topic !== '') && (this.state.data.appRet.topic !== null)) {
                        //Текущий этап
                        let data = this.state.data;
                        data.appRet.step = 2;
                        data.appRet.foodOrMoney = 0;
                        this.setState({ data: data });
                        //добавление к отправке
                        const formData = new FormData();
                        formData.append("step", 2);
                        formData.append("type", 0);
                        formData.append("id", this.state.applicationId);
                        formData.append("reason", this.state.data.appRet.topic);
                        this.submitApplication(formData);
                    } else {
                        errors.appRet.topic = true;
                    }
                break;
            case '4':
                if (this.state.data.appRet.feedArticle === '') {
                    errors.appRet.feedArticle = true;
                } else {
                    console.log(this.state.data.appRet.feedArticle);
                    this.submitApplicationArticle(this.state.data.appRet.feedArticle, this.state.applicationId);
                }
                break;
            case '5':
                let nexTab4Verified = true;
                if ((this.state.data.appRet.batchNumber !== '') && (this.state.data.appRet.batchNumber !== null) && (this.state.data.appRet.batchNumber.length > 18)) {
                    let data = this.state.data;
                    //data.appRet.noCheck = 0;
                    this.setState({ data: data });
                } else {
                    nexTab4Verified = false;
                    errors.appRet.batchNumber = true;
                }
                if ((this.state.data.appRet.feedCost !== '') && (this.state.data.appRet.feedCost !== null) && !isNaN(parseFloat(this.state.data.appRet.feedCost)) && isFinite(this.state.data.appRet.feedCost)) {
                } else {
                    errors.appRet.feedCost = true;
                    nexTab4Verified = false;
                }
                if ((this.state.data.appRet.outletName !== '') && (this.state.data.appRet.outletName !== null)) {
                } else {
                    errors.appRet.outletName = true;
                    nexTab4Verified = false;
                }
                if ((this.state.data.appRet.outletAddress !== '') && (this.state.data.appRet.outletAddress !== null)) {
                } else {
                    errors.appRet.outletAddress = true;
                    nexTab4Verified = false;
                }
                if ((this.state.data.appRet.problem !== '') && (this.state.data.appRet.problem !== null)) {
                } else {
                    errors.appRet.problem = true;
                    nexTab4Verified = false;
                }
                if ((this.file.state.fileList !== '') && (this.file.state.fileList !== null) && (this.file.state.fileList.length !== 0)) {
                } else {
                    errors.appRet.onFilesChange = true;
                    nexTab4Verified = false;
                }
                //data.appRet.foodOrMoney = 0;
                if (nexTab4Verified) {
                    /*console.log(this.state.widjetActive);
                    if (this.state.widjetActive === false) {
                        this.state.widjetActive = true;
                            this.widget();
                    }
                    document.getElementById('apm-scan-qr').click();*/

                    if ((this.state.data.appRet.btnNoCheck)) {
                        this.info12Show();
                    } else {
                        errors.appRet.validate3 = false;

                        const formData = new FormData();
                        formData.append("step", 3);
                        formData.append("id", this.state.applicationId);
                        formData.append("article", this.state.data.appRet.feedArticle);
                        formData.append("code", this.state.data.appRet.batchNumber);
                        formData.append("cost", this.state.data.appRet.feedCost);
                        formData.append("shop", this.state.data.appRet.outletName);
                        formData.append("address", this.state.data.appRet.outletAddress);
                        formData.append("packImages", this.file.state.fileList);
                        for (var x = 0; x < this.file.state.fileList.length; x++) {
                            formData.append("packImages[]", this.file.state.fileList[x]);
                        }
                        formData.append("packImages", this.file.state.fileList2);
                        formData.append("problem", this.state.data.appRet.problem);
                        formData.append("comment", this.state.data.appRet.transferNewFeed);
                        //console.log(this.state.data.appRet.noCheck);
                        if (this.state.data.appRet.noCheck === 0) {
                            errors.appRet.validate3 = true;
                            formData.append("validate", 1);
                        } else {
                            formData.append("type", 0);
                            formData.append("noCheck", 1);
                        }
                        this.submitApplication(formData);
                    }

                } else {
                    this.handleScroll();
                }
                break;
            case '6':
                if ((this.state.data.appRet.trackNumber !== '') && (this.state.data.appRet.trackNumber !== null)) {
                    const formData = new FormData();
                    formData.append("step", 4);
                    formData.append("id", this.state.applicationId);
                    formData.append("track", this.state.data.appRet.trackNumber);
                    formData.append("wantAnswer", this.state.data.appRet.responseCompany);
                    formData.append("postImages", this.file2.state.fileList);
                    for (var x = 0; x < this.file2.state.fileList.length; x++) {
                        formData.append("postImages[]", this.file2.state.fileList[x]);
                    }
                    this.submitApplication(formData);
                } else {
                    errors.appRet.trackNumber = true;
                }
                break;
            case '7':
                if ((this.state.data.appRet.choice !== '') && (this.state.data.appRet.choice !== null)) {
                    const formData = new FormData();
                    formData.append("step", 5);
                    formData.append("id", this.state.applicationId);
                    formData.append("money", this.state.data.appRet.choice);
                    if ((this.state.data.appRet.choice === 0)) {
                        if ((this.state.data.appRet.returnWallet !== '') && (this.state.data.appRet.returnWallet.split('_')[0].length === 15)) {
                            formData.append("destination", this.state.data.appRet.returnWallet);
                            this.submitApplication(formData);
                        } else {
                            errors.appRet.returnWallet = true;
                        }
                    } else if ((this.state.data.appRet.choice === 1)) {
                        if ((this.state.data.appRet.returnPhone !== '') && (this.state.data.appRet.returnPhone.split('_')[0].length === 16)) {
                            formData.append("destination", this.state.data.appRet.returnPhone);
                            this.submitApplication(formData);
                        } else {
                            errors.appRet.returnPhone = true;
                        }
                    } else {
                        this.submitApplication(formData);
                    }
                } else {
                    errors.appRet.choice = true;
                }
                break;
        }
        this.setState({
            errors: errors
        });
    }
    submitReg = async () => {

        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.reg.email === '') {
            errors.reg.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.reg.email)) {
                errors.reg.email = true;
                submit_enable = false;
            } else {
                errors.reg.email = false;
            }
        }

        if (this.state.data.reg.surname === '') {
            errors.reg.surname = true;
            submit_enable = false;
        } else {
            errors.reg.surname = false;
        }
        if (this.state.data.reg.name === '') {
            errors.reg.name = true;
            submit_enable = false;
        } else {
            errors.reg.name = false;
        }
        if (this.state.data.reg.patronymic === '') {
            errors.reg.patronymic = true;
            submit_enable = false;
        } else {
            errors.reg.patronymic = false;
        }

        if (this.state.data.reg.phone === '') {
            errors.reg.phone = true;
            submit_enable = false;
        } else {
            errors.reg.phone = false;
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.reg.email);
            formData.append("name", this.state.data.reg.name);
            formData.append("phone", this.state.data.reg.phone);
            formData.append("personal_data_processing_agreement", 1);


            await fetch(constants.API_URL + '/registerByEmail', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info3Show();
                }

                if (response.error === 1) {
                    if (response.message.pass !== undefined) {
                        errors.reg.pass1 = true;
                        errors.reg.pass1Text = response.message.pass[0];
                    }

                    if (response.message.login !== undefined) {
                        errors.reg.email = true;
                        errors.reg.emailText = response.message.login[0];
                    }

                    if (response.message.phone !== undefined) {
                        errors.reg.phone = true;
                        errors.reg.phoneText = response.message.phone[0];
                    }

                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }

    }
    switchStep = (step) => {
        if (this.state.step === step) {

        } else {
            this.setState({
                step: step
            });
            localStorage.setItem('ApplicationStep', step);
        }
    }
    toggleTopicDropdown = () => {
        let errors = this.state.errors;
        errors.appRet.topic = false;
        this.setState({
            topicDropdown: !this.state.topicDropdown,
            errors: errors
        })
    }
    selectTopic = (topic, title) => {
        let data = this.state.data;
        data.appRet.topic = topic;
        let errors = this.state.errors;
        errors.appRet.topic = false;
        this.setState({
            data: data,
            errors: errors,
            topicDropdown: false,
            topicInput: title
        });
    }

    updateBatchNumber = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/updateDataCode?dataCode=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log('success');
            }

            if (response.error === 1) {
                console.log('error');
             }

            this.setState({
                loading: false
            });
        });
    }

    updateCost = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/updateCost?cost=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log('success');
                localStorage.setItem('cost', Fdata);
            }

            if (response.error === 1) {
                console.log('error');
             }

            this.setState({
                loading: false
            });
        });
    }

    updateShop = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/updateShop?shop=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log('success');
                localStorage.setItem('shop', Fdata);
            }

            if (response.error === 1) {
                console.log('error');
             }

            this.setState({
                loading: false
            });
        });
    }

    updateComment = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/updateComment?comment=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log('success');
                localStorage.setItem('comment', Fdata);
            }

            if (response.error === 1) {
                console.log('error');
             }

            this.setState({
                loading: false
            });
        });
    }

    updateAddress = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/updateAddress?address=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log('success');
                localStorage.setItem('address', Fdata);
            }

            if (response.error === 1) {
                console.log('error');
             }

            this.setState({
                loading: false
            });
        });
    }

    updateProblem = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/updateProblem?problem=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log('success');
                localStorage.setItem('problem', Fdata);
            }

            if (response.error === 1) {
                console.log('error');
             }

            this.setState({
                loading: false
            });
        });
    }

    activeWidjet = () => {
        console.log('sdfsdf');
        this.widget();
    }
    submitApplicationArticle = async (Fdata, Fid) => {
        this.setState({
            loading: true
        });
        console.log(Fid);

        await fetch(constants.API_URL + '/validateArticle?article=' + Fdata + '&id=' + Fid, {
            method: "POST",
            redirect: 'follow'
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                this.setState({
                    NumberStep: this.state.NumberStep + 1,
                    threeStep: true
                });
                localStorage.setItem('threeStep', this.state.threeStep);
                localStorage.setItem('article', Fdata);
            }

            if (response.error === 1) {
                let errors = this.state.errors;
                 errors.appRet.feedArticle = true;
                 errors.appRet.feedArticleText = response.message.article;
                 this.setState({
                     errors: errors
                 });
             }

            this.setState({
                loading: false
            });
        });

    }
    submitApplication = async (Fdata) => {
            let errors = this.state.errors;
            this.setState({
                loading: true
            });

            await fetch(constants.API_URL + '/request', {
                method: "POST",
                body: Fdata,
                headers: {
                    'X-Auth-Token': localStorage.getItem('auth_key')
                }
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.setState({
                        NumberStep: this.state.NumberStep + 1
                    })
                /*this.info3Show();*/
                    const applicationId = response.result.id;
                    if ((this.state.step !== 3) && (this.state.step !== 5)) {
                        this.switchStep(this.state.step + 1);
                        this.setState({
                            applicationId: applicationId
                        })
                        localStorage.setItem('ApplicationId', applicationId);
                    } else if (this.state.step === 5) {
                        if (this.state.data.appRet.choice === 2) {
                            this.info11Show(<a href={response.result.url}>Перейдите по ссылке для заполнения банковских реквизитов</a>, response.result.url);
                        } else {
                            this.info11Show('Заявка на возврат оформлена', '');
                        }
                    } else if ((this.state.step === 3) && (this.state.errors.appRet.validate3 === true)) {
                        errors.appRet.validate3 = false;
                        if (this.state.widjetActive === false) {
                            this.state.widjetActive = true;
                            if (localStorage.getItem('auth_userUuid') !== '' && localStorage.getItem('auth_userUuid') !== undefined && localStorage.getItem('auth_userUuid') !== null) {
                                this.widget();
                            } else {
                                localStorage.removeItem('auth_key');
                                localStorage.removeItem('auth_name');
                                localStorage.removeItem('auth_userUuid');
                                window.location.replace('/');
                            }
                        }
                        if (localStorage.getItem('auth_userUuid') !== '' && localStorage.getItem('auth_userUuid') !== undefined && localStorage.getItem('auth_userUuid') !== null) {
                            document.getElementById('apm-scan-qr').click();
                        } else {
                            localStorage.removeItem('auth_key');
                            localStorage.removeItem('auth_name');
                            localStorage.removeItem('auth_userUuid');
                            window.location.replace('/');
                        }
                    } else {
                        setTimeout(() => {
                            window.location.replace('/applications');
                        }, 1000);
                    }
                }

                if (response.error === 1) {
                    console.log(response);
                    //step 2
                    if (response.message.reason !== undefined) {
                        errors.appRet.topic = true;
                        errors.appRet.topicText = response.message.reason;
                    }

                    //step 3
                    if (response.message.article !== undefined) {
                        errors.appRet.feedArticle = true;
                        errors.appRet.feedArticleText = response.message.article;
                    }
                    if (response.message.code !== undefined) {
                        errors.appRet.batchNumber = true;
                        errors.appRet.batchNumberText = response.message.code;
                    }
                    if (response.message.cost !== undefined) {
                        errors.appRet.feedCost = true;
                        errors.appRet.feedCostText = response.message.cost;
                    }
                    if (response.message.shop !== undefined) {
                        errors.appRet.outletName = true;
                        errors.appRet.outletNameText = response.message.shop;
                    }
                    if (response.message.address !== undefined) {
                        errors.appRet.outletAddress = true;
                        errors.appRet.outletAddressText = response.message.address;
                    }
                    if (response.message.packImages !== undefined) {
                        errors.appRet.onFilesChange = true;
                        errors.appRet.fileListText = response.message.packImages;
                    }
                    if (response.message.problem !== undefined) {
                        errors.appRet.problem = true;
                        errors.appRet.problemText = response.message.problem;
                    }
                    if (response.message.comment !== undefined) {
                        errors.appRet.transferNewFeed = true;
                        errors.appRet.transferNewFeedText = response.message.comment;
                    }

                    //step 4
                    if (response.message.track !== undefined) {
                        errors.appRet.trackNumber = true;
                        errors.appRet.trackNumberText = response.message.track;
                    }
                    if (response.message.postImages !== undefined) {
                        errors.appRet.onFilesChange2 = true;
                        errors.appRet.onFilesChange2Text = response.message.postImages;
                    }


                    if (response.message.destination !== undefined) {
                        errors.appRet.returnWallet = true;
                        errors.appRet.returnWalletText = response.message.destination;
                        errors.appRet.returnPhone = true;
                        errors.appRet.returnPhoneText = response.message.destination;
                    }


                    this.setState({
                        errors: errors
                    });
                    this.handleScroll();

                }

                this.setState({
                    loading: false,
                    errors: errors
                });
            });

    }
    handleChange = (event) => {
        let data = this.state.data;
        data.appRet.outletAddress = event.value;
        this.setState({ data: data });
    }
    render() {
        return (
            <div className="app page-lk page-footer-bottom">
                <Header page={"applicationReturn"} />
                <div className="container section-container">
                    <SlideMenu page={ 'applicationReturn' }/>
                    <div className="slide-content">
                        <form autoComplete="off">
                          <div className="slide-content-container">
                            <div className="section-title">Анкета на возврат</div>
                            <div className="form__list" id="anketa__content">
                                <div className="row">
                                    <div className="col-d-12 col-m-12 form__col box__application-return__steps">
                                        <ul className="application-return__steps">
                                                {/*<li className={[((this.state.step === 1) ? 'active' : '')]}>1</li>*/}
                                            <li className={[((this.state.step === 2) ? 'active' : '')]}>1</li>
                                            <li className={[((this.state.step === 3) ? 'active' : '')]}>2</li>
                                            <li className={[((this.state.step === 4) ? 'active' : '')]}>3</li>
                                            <li className={[((this.state.step === 5) ? 'active' : '')]}>4</li>
                                        </ul>
                                        </div>
                                    <div className={['application__steps ' + ((this.state.step === 1) ? 'active' : '')]}>
                                        <div className="application__step">
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="form-text">1. Вы хотите заменить корм или вернуть деньги?</div>
                                                <div onClick={() => {
                                                    let data = this.state.data;
                                                    data.appRet.foodOrMoney = 0;
                                                    this.setState({ data: data });
                                                    this.nextTab('2');
                                                }} className="btn btn-blue size-min">
                                                    <span>Хочу заменить корм</span>
                                                </div>
                                                <div onClick={() => {
                                                    let data = this.state.data;
                                                    data.appRet.foodOrMoney = 1;
                                                    this.setState({ data: data });
                                                    this.nextTab('2');
                                                }} className="btn btn-gray size-min">
                                                    <span>Хочу вернуть деньги</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={['application__steps ' + ((this.state.step === 2) ? 'active' : '')]}>
                                        <div className="application__step">
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="form-text">1. Почему вы хотите вернуть корм?</div>
                                                <div className={['select__custom', ((this.state.errors.appRet.topic === true) ? 'has-error' : '')].join(' ')}>
                                                    <div className="form__label" onClick={() => {
                                                        this.setState({
                                                            topicDropdown: true
                                                        })
                                                    }}>
                                                        Причина обращения *
                                                            </div>
                                                    <div onClick={() => this.toggleTopicDropdown()} className="select__custom-input d-flex align-items-center">
                                                        <span>{this.state.topicInput}</span>
                                                        <div className="select__custom-arrow d-flex align-items-center justify-content-center">
                                                            <i className={'select__arrow ' + ((this.state.topicDropdown === true) ? 'active' : '')}></i>
                                                        </div>
                                                    </div>
                                                    <div className={'select__custom-dropdown ' + ((this.state.topicDropdown === true) ? 'active' : '')}>
                                                        <div className="select__custom-dropdown-list">
                                                            <div onClick={() => this.selectTopic(0, 'Питомец не ест корм / Перестал есть корм')} className={'select__custom-dropdown-item ' + ((this.state.data.appRet.topic ===  0) ? 'active' : '')}>
                                                                Питомец не ест корм / перестал есть корм
                                                                    </div>
                                                            <div onClick={() => this.selectTopic(1, 'Питомец ест корм, но есть негативная реакция организма на корм')} className={'select__custom-dropdown-item ' + ((this.state.data.appRet.topic === 1) ? 'active' : '')}>
                                                                Питомец ест корм, но есть негативная реакция организма на корм
                                                                    </div>
                                                            <div onClick={() => this.selectTopic(2, 'Упаковка с кормом была испорчена (помята, порвана и тд.)')} className={'select__custom-dropdown-item ' + ((this.state.data.appRet.topic === 2) ? 'active' : '')}>
                                                                Упаковка с кормом была испорчена (помята, порвана и т.д.)
                                                                    </div>
                                                            <div onClick={() => this.selectTopic(3, 'Структура корма выглядит странно')} className={'select__custom-dropdown-item ' + ((this.state.data.appRet.topic === 3) ? 'active' : '')}>
                                                                Текстура  корма выглядит странно
                                                                    </div>
                                                            <div onClick={() => this.selectTopic(4, 'Другая причина')} className={'select__custom-dropdown-item ' + ((this.state.data.appRet.topic === 4) ? 'active' : '')}>
                                                                Другая причина
                                                                    </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.renderError('appRet', 'topic')
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="form-text-min">Вы уверены, что в вашей пачке осталось не менее 70% корма?</div>
                                                <div onClick={() => {
                                                    let data = this.state.data;
                                                    this.state.data.appRet.moreFeed = 1;
                                                    this.nextTab('3');
                                                }} className="btn btn-blue size-min">
                                                    <span>Да</span>
                                                </div>
                                                <div onClick={() => {
                                                    this.state.data.appRet.moreFeed = 2;
                                                    this.nextTab('3');
                                                }} className="btn btn-gray size-min">
                                                    <span>Нет</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={['application__steps three1 ' + ((this.state.step === 3) ? 'active ' : '') + ((this.state.threeStep === true) ? 'activeThreeStep' : '')]}>
                                        <div className="application__step">
                                            <div className="col-d-12 col-m-12 form__text">
                                                <div className="form-text">2. Ответьте пожалуйста на вопросы ниже</div>
                                            </div>
                                            <div className="col-d-12 col-m-12 d__flex">
                                                <div className="col-d-6 col-m-12 form__col">
                                                    <div className={'form__item ' + ((this.state.data.appRet.feedArticle === true || this.state.data.appRet.feedArticle !== '') ? 'form-focused' : '')}>
                                                        <div className={['form__field', ((this.state.errors.appRet.feedArticle === true) ? 'has-error' : '')].join(' ')}>
                                                            <div className="form__label" onClick={() => {
                                                                this.feedArticleInput.focus();
                                                            }}>
                                                                Артикул корма *
                                                            </div>
                                                            <input placeholder="Введите артикул" ref={(feedArticle) => { this.feedArticleInput = feedArticle; }} type="text" onFocus={() => {
                                                                let data = this.state.data;
                                                                data.appRet.feedArticleFocus = true;
                                                                let errors = this.state.errors;
                                                                errors.appRet.feedArticle = false;
                                                                this.setState({ errors: errors, data: data });
                                                            }} onChange={(feedArticle) => {
                                                                let data = this.state.data;
                                                                data.appRet.feedArticle = feedArticle.target.value;
                                                                this.setState({ data: data });
                                                            }} onBlur={() => {
                                                                let data = this.state.data;
                                                                data.appRet.feedArticleFocus = false;
                                                                this.setState({ data: data });
                                                                }} autoComplete="off" />
                                                            {
                                                                this.renderError('appRet', 'feedArticle')
                                                            }
                                                        </div>
                                                        <div style={{ display: 'none' }}>
                                                            <input type="text" id="PreventChromeAutocomplete"
                                                                name="PreventChromeAutocomplete" autoComplete="address-level4" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-d-6 col-m-12 form__col align-center">
                                                    <div className="tooltip">
                                                            <span className="ico ico-question">?</span>
                                                            <div className="tooltip-inner">
                                                                <div className="col-d-12 d__flex">
                                                                    <div className="col-d-6 tooltip-inner-item"><div className="tooltip-inner-text">1. На упаковке сухого корма</div><img src={ art1 } /></div>
                                                                    <div className="col-d-6 tooltip-inner-item"><div className="tooltip-inner-text">2. На паучах с влажным кормом</div><img src={ art2 } /></div>
                                                                </div>
                                                                <div className="col-d-12">
                                                                    <div className="col-d-6 tooltip-inner-item"><div className="tooltip-inner-text">3. На консервной банке</div><img src={art3} /></div>
                                                                </div>
                                                            </div>
                                                        Где находится артикул?
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div onClick={() => {
                                                    this.nextTab('4');
                                                }} className="btn btn-blue size-min">
                                                    <span>Продолжить</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={['application__steps three2 ' + ((this.state.step === 3) ? 'active ' : '') + ((this.state.threeStep === true) ? 'activeThreeStep' : '')]}>
                                        <div className="application__step">
                                            <div className="col-d-12 col-m-12 d__flex">
                                                <div className="col-d-6 col-m-12 form__col2">
                                                    <div className={'form__item ' + ((this.state.data.appRet.batchNumber === true || this.state.data.appRet.batchNumber !== '') ? 'form-focused' : '')}>
                                                        <div className={['form__field', ((this.state.errors.appRet.batchNumber === true) ? 'has-error' : '')].join(' ')}>
                                                            <div className="form__label" onClick={() => {
                                                                this.batchNumberInput.focus();
                                                            }}>
                                                                Дата-код (номер партии) корма *
                                                            </div>
                                                            <input value={this.state.data.appRet.batchNumber} placeholder="5322465476" ref={(batchNumber) => { this.batchNumberInput = batchNumber; }} type="text" onFocus={() => {
                                                                let data = this.state.data;
                                                                data.appRet.batchNumberFocus = true;
                                                                let errors = this.state.errors;
                                                                errors.appRet.batchNumber = false;
                                                                this.setState({ errors: errors, data: data });
                                                            }} onChange={(batchNumber) => {
                                                                let data = this.state.data;
                                                                data.appRet.batchNumber = batchNumber.target.value;
                                                                this.setState({ data: data });
                                                            }} onBlur={() => {
                                                                let data = this.state.data;
                                                                data.appRet.batchNumberFocus = false;
                                                                this.setState({ data: data });
                                                                this.updateBatchNumber(this.state.data.appRet.batchNumber, this.state.applicationId);
                                                                }} autoComplete="off" />
                                                            {
                                                                this.renderError('appRet', 'batchNumber')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-d-6 col-m-12 form__col2 align-center">
                                                    <div className="tooltip">
                                                        <span className="ico ico-question">?</span>
                                                            <div className="tooltip-inner">
                                                                <div className="col-d-12 d__flex">
                                                                    <div className="col-d-6 tooltip-inner-item"><div className="tooltip-inner-text">1. На упаковке сухого корма</div><img src={data1} /></div>
                                                                    <div className="col-d-6 tooltip-inner-item"><div className="tooltip-inner-text">2. На паучах с влажным кормом</div><img src={data2} /></div>
                                                                </div>
                                                                <div className="col-d-12">
                                                                    <div className="col-d-6 tooltip-inner-item"><div className="tooltip-inner-text">3. На консервной банке</div><img src={data3} /></div>
                                                                </div>
                                                            </div>
                                                        Где находится дата-код?
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 d__flex">
                                                <div className="col-d-6 col-m-12 form__col2">
                                                    <div className={'form__item ' + ((this.state.data.appRet.feedCost === true || this.state.data.appRet.feedCost !== '') ? 'form-focused' : '')}>
                                                        <div className={['form__field', ((this.state.errors.appRet.feedCost === true) ? 'has-error' : '')].join(' ')}>
                                                            <div className="form__label" onClick={() => {
                                                                this.feedCostInput.focus();
                                                            }}>
                                                                Стоимость корма в чеке *
                                                            </div>
                                                                <input defaultValue={this.state.data.appRet.feedCost} placeholder="0.00 ₽" autoComplete="off" ref={(feedCost) => { this.feedCostInput = feedCost; }} type="text" onFocus={() => {
                                                                let data = this.state.data;
                                                                data.appRet.feedCostFocus = true;
                                                                let errors = this.state.errors;
                                                                errors.appRet.feedCost = false;
                                                                this.setState({ errors: errors, data: data });
                                                            }} onChange={(feedCost) => {
                                                                let data = this.state.data;
                                                                data.appRet.feedCost = feedCost.target.value;
                                                                this.setState({ data: data });
                                                            }} onBlur={() => {
                                                                let data = this.state.data;
                                                                data.appRet.feedCostFocus = false;
                                                                this.setState({ data: data });
                                                                this.updateCost(this.state.data.appRet.feedCost, this.state.applicationId);

                                                            }} />
                                                            {
                                                                this.renderError('appRet', 'feedCost')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-d-6 col-m-12 form__col align-center hidden-mob">
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 d__flex form__col2">
                                                <div className={'form__item ' + ((this.state.data.appRet.outletName === true || this.state.data.appRet.outletName !== '') ? 'form-focused' : '')}>
                                                    <div className={['form__field', ((this.state.errors.appRet.outletName === true) ? 'has-error' : '')].join(' ')}>
                                                        <div className="form__label" onClick={() => {
                                                            this.outletNameInput.focus();
                                                        }}>
                                                            Название торговой точки *
                                                        </div>
                                                        <input defaultValue={this.state.data.appRet.outletName} placeholder="Введите название" ref={(outletName) => { this.outletNameInput = outletName; }} type="text" onFocus={() => {
                                                            let data = this.state.data;
                                                            data.appRet.outletNameFocus = true;
                                                            let errors = this.state.errors;
                                                            errors.appRet.outletName = false;
                                                            this.setState({ errors: errors, data: data });
                                                        }} onChange={(outletName) => {
                                                            let data = this.state.data;
                                                            data.appRet.outletName = outletName.target.value;
                                                            this.setState({ data: data });
                                                        }} onBlur={() => {
                                                            console.log(1);
                                                            let data = this.state.data;
                                                            data.appRet.outletNameFocus = false;
                                                            this.setState({ data: data });
                                                            this.updateShop(this.state.data.appRet.outletName, this.state.applicationId);

                                                                }} autoComplete="off" />
                                                        {
                                                            this.renderError('appRet', 'outletName')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 d__flex form__col">
                                                <div className={'form__item ' + ((this.state.data.appRet.outletAddress === true || this.state.data.appRet.outletAddress !== '') ? 'form-focused' : '')}>
                                                    <div className={['form__field', ((this.state.errors.appRet.outletAddress === true) ? 'has-error' : '')].join(' ')}>
                                                        <div className="form__label">
                                                            Адрес торговой точки *
                                                        </div>
                                                            <DadataSuggestions ref={(outletAddress) => { this.outletAddressInput = outletAddress; }}
                                                                token={'e3468f8470bd9bff626cc9a80dfc9cceb5d01020'}
                                                                onFocus={() => {
                                                                    let data = this.state.data;
                                                                    data.appRet.outletAddressFocus = true;
                                                                    let errors = this.state.errors;
                                                                    errors.appRet.outletAddress = false;
                                                                    this.setState({ errors: errors, data: data });
                                                                }}
                                                                onBlur={() => {
                                                                    let data = this.state.data;
                                                                    data.appRet.outletAddressFocus = false;
                                                                    this.setState({ data: data });

                                                                }}
                                                                onSelect={
                                                                    suggestion => {
                                                                        let data = this.state.data;
                                                                        data.appRet.outletAddress = suggestion.value;
                                                                        this.updateAddress(this.state.data.appRet.outletAddress, this.state.applicationId);
                                                                        this.setState({ data: data });
                                                                }}
                                                                deferRequestBy={300}
                                                                placeholder={"Введите адрес торговой точки"}
                                                                onChange={query => console.log(query)}
                                                                query={this.state.data.appRet.outletAddress }
                                                                receivePropsBehaveLikeOnChange={true}
                                                            />
                                                        {
                                                            this.renderError('appRet', 'outletAddress')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="col-d-12 col-m-12 onFilesChange">
                                                    <div className="form-text-min">Загрузите фотографии упаковки со всех сторон и внутри упаковки*</div>
                                                    <File runWidget={this.runWidget} ref={instance => { this.file = instance; }} fileErr={this.state.errors.appRet.onFilesChange} onClickItem={item => {
                                                        this.setState({
                                                            fileError: true
                                                        });
                                                        let errors = this.state.errors;
                                                        errors.appRet.onFilesChange = item;
                                                        this.setState({ errors: errors });
                                                    }}/>
                                                    {
                                                        this.renderError('appRet', 'onFilesChange')
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="col-d-12 col-m-12 d__flex">
                                                    <div className="form-text-min">Опишите, пожалуйста, что произошло после покупки корма?</div>
                                                </div>
                                                <div className="col-d-12 col-m-12 d__flex">
                                                    <div className={'form__item ' + ((this.state.data.appRet.problem === true || this.state.data.appRet.problem !== '') ? 'form-focused' : '')}>
                                                        <div className={['form__field', ((this.state.errors.appRet.problem === true) ? 'has-error' : '')].join(' ')}>
                                                            <div className="form__label" onClick={() => {
                                                                this.problemInput.focus();
                                                            }}>
                                                                Опишите проблему *
                                                            </div>
                                                            <textarea defaultValue={this.state.data.appRet.problem} ref={(problem) => { this.problemInput = problem; }} type="text" onFocus={() => {
                                                                let data = this.state.data;
                                                                data.appRet.problemFocus = true;
                                                                let errors = this.state.errors;
                                                                errors.appRet.problem = false;
                                                                this.setState({ errors: errors, data: data });
                                                            }} onChange={(problem) => {
                                                                let data = this.state.data;
                                                                data.appRet.problem = problem.target.value;
                                                                this.setState({ data: data });
                                                            }} onBlur={() => {
                                                                let data = this.state.data;
                                                                data.appRet.problemFocus = false;
                                                                this.setState({ data: data });
                                                                this.updateProblem(this.state.data.appRet.problem, this.state.applicationId);
                                                                }} autoComplete="off"/>
                                                            {
                                                                this.renderError('appRet', 'problem')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="col-d-12 col-m-12 d__flex">
                                                        <div className="form-text-min">Если корм для питомца новый, пожалуйста, опишите, как Вы переводили его на новый корм? Пользовались ли Вы <a href="/feeding-recommendations" target="_blank">рекомендацией</a> по постепенному переходу на новый корм?</div>
                                                </div>
                                                <div className="col-d-12 col-m-12 d__flex">
                                                    <div className={'form__item ' + ((this.state.data.appRet.transferNewFeed === true || this.state.data.appRet.transferNewFeed !== '') ? 'form-focused' : '')}>
                                                        <div className={['form__field', ((this.state.errors.appRet.transferNewFeed === true) ? 'has-error' : '')].join(' ')}>
                                                            <div className="form__label" onClick={() => {
                                                                this.transferNewFeedInput.focus();
                                                            }}>
                                                                Как был произведен перевод **
                                                            </div>
                                                            <textarea defaultValue={this.state.data.appRet.transferNewFeed} ref={(transferNewFeed) => { this.transferNewFeedInput = transferNewFeed; }} type="text" onFocus={() => {
                                                                let data = this.state.data;
                                                                data.appRet.transferNewFeedFocus = true;
                                                                let errors = this.state.errors;
                                                                errors.appRet.transferNewFeed = false;
                                                                this.setState({ errors: errors, data: data });
                                                            }} onChange={(transferNewFeed) => {
                                                                let data = this.state.data;
                                                                data.appRet.transferNewFeed = transferNewFeed.target.value;
                                                                this.setState({ data: data });
                                                            }} onBlur={() => {
                                                                let data = this.state.data;
                                                                data.appRet.transferNewFeedFocus = false;
                                                                this.setState({ data: data });
                                                                this.updateComment(this.state.data.appRet.transferNewFeed, this.state.applicationId);
                                                                }} autoComplete="off" />
                                                            {
                                                                this.renderError('appRet', 'transferNewFeed')
                                                            }
                                                                <div className="form__info">* - поля обязательные для заполнения</div>
                                                                <div className="form__info">** - Оставьте поле пустым, в случае если не было перевода</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-d-12 col-m-12">
                                                    <div onClick={() => {
                                                                let data = this.state.data;
                                                                data.appRet.btnNoCheck = false;
                                                                data.appRet.noCheck = 0;


                                                                this.setState({ data: data });
                                                                //console.log(this.state.data.appRet.noCheck);
                                                                this.nextTab('5');
                                                        }} className="btn btn-blue size-min d-float-left">
                                                        <span>Загрузить чек на покупку</span>
                                                    </div>
                                                        <div className="tooltip d-float-left tooltip-btn tooltip_top m-tooltip__bottom">
                                                            <span className="ico ico-question">?</span>
                                                            <div className="tooltip-inner">
                                                                <div className="col-d-12 d__flex">
                                                                    <div className="col-d-12 tooltip-inner-item"><img src={data4} /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <div id="apm-scan-qr" className="btn btn-blue size-min custom-main-button" style={{ display: 'none' }}>
                                                        <span>Загрузить чек на покупку</span>
                                                    </div>
                                                    <div id="apm-widget" className="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={['application__steps ' + ((this.state.step === 4) ? 'active' : '')]}>
                                        <div className="application__step">
                                            <div className="col-d-12 col-m-12 form__text form__col">
                                                <div className="form-text">4. Заполните данные о почтовом отправлении</div>
                                                <p>Посылка, отвечающая требованиям п.4.1.11 Правил Акции, должна быть отправлена Почтой России простым почтовым отправлением на&nbsp;следующий адрес: 119002&nbsp;Москва, Арбат, ул. 47/23, а/я 23, ООО "АМГ"</p>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__text form__col">
                                                <div className="form-text-min">Загрузите фотографии чека отправки посылки</div>
                                                <File runWidget={this.runWidget} ref={instance => { this.file2 = instance; }} fileErr={this.state.errors.appRet.onFilesChange2} onClickItem={item => {

                                                    this.setState({
                                                        fileError: true
                                                    });
                                                    let errors = this.state.errors;
                                                    errors.appRet.onFilesChange2 = item;
                                                    this.setState({ errors: errors });
                                                }} />
                                                {
                                                    this.renderError('appRet', 'onFilesChange2')
                                                }
                                            </div>
                                            <div className="col-d-12 col-m-12 d__flex form__col">
                                                <div className={'form__item ' + ((this.state.data.appRet.trackNumber === true || this.state.data.appRet.trackNumber !== '') ? 'form-focused' : '')}>
                                                    <div className={['form__field', ((this.state.errors.appRet.trackNumber === true) ? 'has-error' : '')].join(' ')}>
                                                        <div className="form__label" onClick={() => {
                                                            this.trackNumberInput.focus();
                                                        }}>
                                                            Укажите трек-номер отправления *
                                                        </div>
                                                        <input placeholder="76542368421268" ref={(trackNumber) => { this.trackNumberInput = trackNumber; }} type="number" onFocus={() => {
                                                            let data = this.state.data;
                                                            data.appRet.trackNumberFocus = true;
                                                            let errors = this.state.errors;
                                                            errors.appRet.trackNumber = false;
                                                            this.setState({ errors: errors, data: data });
                                                        }} onChange={(trackNumber) => {
                                                            let data = this.state.data;
                                                            data.appRet.trackNumber = trackNumber.target.value;
                                                            this.setState({ data: data });
                                                        }} onBlur={() => {
                                                            let data = this.state.data;
                                                            data.appRet.trackNumberFocus = false;
                                                            this.setState({ data: data });
                                                            }} autoComplete="off" />
                                                        {
                                                            this.renderError('appRet', 'trackNumber')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div className="form-text-min">Хотели&nbsp;бы Вы, чтобы вместе с&nbsp;данным возвратом получить официальный ответ от&nbsp;компании Hill&rsquo;s? </div>
                                                <div onClick={() => {
                                                    this.nextTab('6');
                                                    this.state.data.appRet.responseCompany = 1;
                                                }} className="btn btn-blue size-min">
                                                    <span>Да</span>
                                                </div>
                                                <div onClick={() => {
                                                    this.nextTab('6');
                                                    this.state.data.appRet.responseCompany = 0;
                                                }} className="btn btn-gray size-min">
                                                    <span>Нет</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={['application__steps ' + ((this.state.step === 5) ? 'active' : '')]}>
                                        <div className="application__step">
                                            <div className="col-d-12 col-m-12 form__text form__col">
                                                <div className="form-text">5. Выберите Способ получения денег</div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__text form__col">
                                                ШАГ 4 Будет доступен после того, как мы получим упаковку корма, отправленную на адрес: 119002, Москва, Арбат, ул. 47/23, а/я 23, ООО "АМГ"
                                            </div>
                                                <div className={['col-d-6 col-m-12 d__flex ' + ((this.state.data.appRet.choice === 0) ? '' : 'd__none')]}>
                                                    <div className={'form__item ' + ((this.state.data.appRet.returnWallet === true || this.state.data.appRet.returnWallet !== '') ? 'form-focused' : '')}>
                                                        <div className={['form__field', ((this.state.errors.appRet.returnWallet === true) ? 'has-error' : '')].join(' ')}>
                                                        <div className="form__label" onClick={() => {
                                                                this.returnWalletInput.focus();
                                                        }}>
                                                            Номер кошелька *
                                                    </div>
                                                            <InputMask mask="999999999999999" placeholder="Введите номер кошелька" ref={(returnWallet) => { this.returnWalletInput = returnWallet; }} type="text" onFocus={() => {
                                                            let data = this.state.data;
                                                                data.appRet.returnWalletFocus = true;
                                                            let errors = this.state.errors;
                                                                errors.appRet.returnWallet = false;
                                                            this.setState({ errors: errors, data: data });
                                                            }} onChange={(returnWallet) => {
                                                            let data = this.state.data;
                                                                data.appRet.returnWallet = returnWallet.target.value;
                                                            this.setState({ data: data });
                                                        }} onBlur={() => {
                                                            let data = this.state.data;
                                                            data.appRet.returnWalletFocus = false;
                                                            this.setState({ data: data });
                                                        }} autoComplete="off" />
                                                        {
                                                                this.renderError('appRet', 'returnWallet')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={['col-d-6 col-m-12 d__flex ' + ((this.state.data.appRet.choice === 1) ? '' : 'd__none')]}>
                                                <div className={'form__item ' + ((this.state.data.appRet.returnPhoneFocus === true || this.state.data.appRet.returnPhone !== '') ? 'form-focused' : '')}>

                                                    <div className={['form__field ', ((this.state.errors.appRet.returnPhone === true) ? 'has-error' : '')].join(' ')}>
                                                        <div className="form__label" onClick={() => {
                                                            this.returnPhone2Input.querySelector('input').focus();
                                                        }}>
                                                            Номер телефона
                                                        </div>
                                                        <div ref={(returnPhone2) => { this.returnPhone2Input = returnPhone2; }}>
                                                            <InputMask type="returnPhone" mask="+7(999)999-99-99" placeholder="+7 - _ _ _ - _ _ _ - _ _ - _ _" alwaysShowMask={false} value=            {this.state.data.appRet.returnPhone} onBlur={() => {
                                                                let data = this.state.data;
                                                                data.appRet.returnPhoneFocus = false;
                                                                this.setState({ data: data });
                                                            }} onFocus={() => {
                                                                let data = this.state.data;
                                                                let errors = this.state.errors;
                                                                data.appRet.returnPhoneFocus = true;
                                                                errors.appRet.returnPhone = false;
                                                                this.setState({
                                                                    data: data,
                                                                    errors: errors
                                                                });
                                                            }} onChange={(returnPhone) => {
                                                                let data = this.state.data;
                                                                data.appRet.returnPhone = returnPhone.target.value;
                                                                this.setState({ data: data });
                                                            }} />
                                                            {
                                                                this.renderError('appRet', 'returnPhone')
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-d-12 col-m-12 form__col">
                                                <div onClick={() => {
                                                    this.nextTab('7');
                                                }} className="btn btn-blue size-min">
                                                    <span>Продолжить</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer btnFeedbackShow={this.btnFeedbackShow} />
                <Modals runWidget={this.runWidget} ref={instance => { this.modals = instance; }} onClickItem={item => {
                    if (this.state.step === 2) {
                        this.nextTab('32');
                    } else if (this.state.step === 3) {
                        let data = this.state.data;
                        data.appRet.noCheck = 1;
                        data.appRet.foodOrMoney = 0;
                        data.appRet.btnNoCheck = false;
                        this.setState({ data: data });
                        this.nextTab('5');
                    }
                }}/>
            </div>
        );
    }
}
