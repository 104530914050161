import React from 'react'
import File from "./Files";
import * as constants from '../constants';

const recaptchaRef = React.createRef();
const recaptchaRef2 = React.createRef();
const recaptchaRef3 = React.createRef();
const recaptchaRef4 = React.createRef();

export default class Modals extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            your_site_key: '6LcZvb0UAAAAAGWachHAtQkfEN1WtATm-nSiVDgJ',
            submit: false,
            loading: false,
            citySugg: [],
            modal__reg: false,
            modal__lk: false,
            modal__login: false,
            modal__recovery: false,
            modal__change: false,
            modal__feedback: false,
            modal__close_site: false,
            modal__info_1: false,
            modal__info_2: false,
            modal__info_3: false,
            modal__info_4: false,
            modal__info_5: false,
            modal__info_6: false,
            modal__info_7: false,
            modal__info_8: false,
            modal__info_9: false,
            modal__info_10: false,
            modal__info_11: false,
            modal__info_12: false,
            modal__shops: false,
            modal__del: false,
            passType: 'password',
            rejectionReason: '2',
            mUrl: '',
            data: {
                feedback: {
                    email: '',
                    emailFocus: false,
                    name: '',
                    nameFocus: false,
                    topic: '',
                    topicFocus: false,
                    message: '',
                    messageFocus: false,
                    onFilesChange3: []
                },
                recovery: {
                    email: '',
                    emailFocus: false
                }
            },
            errors: {
                feedback: {
                    email: false,
                    name: false,
                    topic: false,
                    message: false,
                    nameText: 'Введено некорректное имя',
                    emailText: 'Введен некорректный e-mail',
                    topicText: 'Ввыберите тему сообщения',
                    messageText: 'Сообщение должно быть больше 5 символов',
                    onFilesChange3: false,
                    onFilesChange3Text: 'Фотографии не загружены'
                },
                recovery: {
                    email: false,
                    emailText: 'Введен некорректный e-mail'
                }
            },
            topicInput: 'Выберите тему обращения',
            topicDropdown: false,
            f_file: null,
            f_file_label: 'Изображение'

        }
    }

    componentDidMount() {
        /*this.feedbackShow();*/
    /*this.shopRecovery();*/
        if(localStorage.getItem('info5') !== '1' ) {
        }
        //this.info5Show();
    }


    modalClose = () => {
        document.body.classList.remove('overflow-disabled');
        this.setState({
            modal__reg: false,
            modal__lk: false,
            modal__login: false,
            modal__change: false,
            modal__feedback: false,
            modal__info_1: false,
            modal__info_2: false,
            modal__info_3: false,
            modal__info_4: false,
            modal__info_5: false,
            modal__info_6: false,
            modal__info_7: false,
            modal__info_8: false,
            modal__info_9: false,
            modal__info_10: false,
            modal__info_11: false,
            modal__info_12: false,
            modal__shops: false,
            modal__del: false,
            modal__recovery: false,
            data: {
                feedback: {
                    email: '',
                    emailFocus: false,
                    name: '',
                    nameFocus: false,
                    topic: '',
                    topicFocus: false,
                    message: '',
                    messageFocus: false,
                    onFilesChange3: []
                },
                recovery: {
                    email: '',
                    emailFocus: false
                }
            },
            errors: {
                feedback: {
                    email: false,
                    name: false,
                    topic: false,
                    message: false,
                    nameText: 'Введено некорректное имя',
                    emailText: 'Введен некорректный e-mail',
                    topicText: 'Ввыберите тему сообщения',
                    messageText: 'Сообщение должно быть больше 5 символов',
                    onFilesChange3: false,
                    onFilesChange3Text: 'Фотографии не загружены'
                },
                recovery: {
                    email: false,
                    emailText: 'Введен некорректный e-mail'
                }
            }
        })
    }

    feedbackShow = () => {
        this.modalClose();
        this.setState({
            modal__feedback: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    shopShow = () => {
        this.modalClose();
        this.setState({
            modal__shops: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    shopRecovery = () => {
        this.modalClose();
        this.setState({
            modal__recovery: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info1Show = () => {
        this.modalClose();
        this.setState({
            modal__info_1: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info2Show = () => {
        this.modalClose();
        this.setState({
            modal__info_2: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info3Show = () => {
        this.modalClose();
        this.setState({
            modal__info_3: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info4Show = () => {
        this.modalClose();
        this.setState({
            modal__info_4: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info5Show = () => {
        this.modalClose();
        this.setState({
            modal__info_5: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info6Show = () => {
        this.modalClose();
        this.setState({
            modal__info_6: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info7Show = () => {
        this.modalClose();
        this.setState({
            modal__info_7: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info8Show = () => {
        this.modalClose();
        this.setState({
            modal__info_8: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info9Show = () => {
        this.modalClose();
        this.setState({
            modal__info_9: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info10Show = (modalText) => {
        this.modalClose();
        this.setState({
            rejectionReason: modalText,
            modal__info_10: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info11Show = (modalText, modalUrl) => {
        this.modalClose();
        this.setState({
            rejectionReason: modalText,
            mUrl: modalUrl,
            modal__info_11: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    info12Show = () => {
        this.modalClose();
        this.setState({
            modal__info_12: true
        }, () => {
            document.body.classList.add('overflow-disabled');
        })
    }

    renderError = (form, field) => {
        switch (form) {
            case 'feedback':
                switch (field) {
                    case 'onFilesChange3':
                        if (this.state.errors.feedback.onFilesChange3 === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.feedback.onFilesChange3 = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.feedback.onFilesChange3Text}
                                </div>
                            )
                        }
                        break;
                    case 'name':
                        if (this.state.errors.feedback.name === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.feedback.name = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.feedback.nameText}
                                </div>
                            )
                        }
                        break;
                    case 'email':
                        if (this.state.errors.feedback.email === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.feedback.email = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.feedback.emailText}
                                </div>
                            )
                        }
                        break;
                    case 'topic':
                        if (this.state.errors.feedback.topic === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.feedback.topic = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.feedback.topicText}
                                </div>
                            )
                        }
                        break;
                    case 'message':
                        if (this.state.errors.feedback.message === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.feedback.message = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.feedback.messageText}
                                </div>
                            )
                        }
                        break;
                    case 'captch':
                        if (this.state.errors.feedback.captch === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.feedback.captch = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.feedback.captchText}
                                </div>
                            )
                        }
                        break;
                }
                break;
        }
    }

    submitLogin = async () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.state.data.login.catch = recaptchaValue;

        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.login.captch === '') {
            errors.login.captch = true;
            submit_enable = false;
        } else {
            errors.reg.captch = false;
        }
        if (this.state.data.login.email === '') {
            errors.login.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.login.email)) {
                errors.login.email = true;
                submit_enable = false;
            } else {
                errors.login.email = false;
            }
        }

        if (this.state.data.login.password === '') {
            errors.login.password = true;
            submit_enable = false;
        } else {
            errors.login.password = false;
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.login.email);
            formData.append("password", this.state.data.login.password.replace(/\s/g, ""));
            formData.append("g-recaptcha-response", this.state.data.login.catch);
            await fetch(constants.API_URL + '/authenticateByEmail', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    localStorage.setItem('auth_key', response.auth_key);
                    localStorage.setItem('auth_name', response.name);
                    /*window.location.reload();*/
                    window.location.replace("/cabinet");
                }

                if (response.error === 1) {
                    if (response.message.password !== undefined) {
                        errors.login.password = true;
                        errors.login.passwordText = response.message.password;
                    }

                    if (response.message.login !== undefined) {
                        errors.login.email = true;
                        errors.login.emailText = response.message.login[0];
                    }

                    if (response.message.recaptcha !== undefined) {
                        errors.login.captch = true;
                        errors.login.captchText = response.message.recaptcha[0];
                        recaptchaRef.current.reset();
                    }

                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }

    }

    submitReg = async () => {
        this.state.data.reg.captch = recaptchaRef2.current.getValue();

        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.reg.captch === '') {
            errors.reg.captch = true;
            submit_enable = false;
        } else {
            errors.reg.captch = false;
        }
        if (this.state.data.reg.email === '') {
            errors.reg.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.reg.email)) {
                errors.reg.email = true;
                submit_enable = false;
            } else {
                errors.reg.email = false;
            }
        }

        if (this.state.data.reg.name === '') {
            errors.reg.name = true;
            submit_enable = false;
        } else {
            errors.reg.name = false;
        }

        if (this.state.data.reg.phone === '') {
            errors.reg.phone = true;
            submit_enable = false;
        } else {
            errors.reg.phone = false;
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.reg.email);
            formData.append("name", this.state.data.reg.name);
            formData.append("phone", this.state.data.reg.phone);
            formData.append("g-recaptcha-response", this.state.data.reg.captch);
            formData.append("personal_data_processing_agreement", 1);


            await fetch(constants.API_URL + '/registerByEmail', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info3Show();
                }

                if (response.error === 1) {
                    if (response.message.pass !== undefined) {
                        errors.reg.pass1 = true;
                        errors.reg.pass1Text = response.message.pass[0];
                    }

                    if (response.message.login !== undefined) {
                        errors.reg.email = true;
                        errors.reg.emailText = response.message.login[0];
                    }

                    if (response.message.phone !== undefined) {
                        errors.reg.phone = true;
                        errors.reg.phoneText = response.message.phone[0];
                    }
                    if (response.message.recaptcha !== undefined) {
                        errors.reg.captch = true;
                        errors.reg.captchText = response.message.recaptcha[0];
                        recaptchaRef2.current.reset();
                    }

                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    submitRecovery = async () => {
        let errors = this.state.errors;
        let submit_enable = true;
        this.state.data.recovery.captch = recaptchaRef3.current.getValue();
        if (this.state.data.recovery.captch === '') {
            errors.recovery.captch = true;
            submit_enable = false;
        } else {
            errors.recovery.captch = false;
        }
        if (this.state.data.recovery.email === '') {
            errors.recovery.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.recovery.email)) {
                errors.recovery.email = true;
                submit_enable = false;
            } else {
                errors.recovery.email = false;
            }
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.recovery.email);
            formData.append("g-recaptcha-response", this.state.data.recovery.captch);

            await fetch(constants.API_URL + '/resendPassword', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info1Show()
                }

                if (response.error === 1) {
                    if (response.message.common !== undefined) {
                        errors.recovery.email = true;
                        errors.recovery.emailText = response.message.common[0];
                    }
                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    submitChange = async () => {
        this.state.data.change.captch = recaptchaRef.current.getValue();
        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.change.pass1 === '') {
            errors.change.pass1 = true;
            submit_enable = false;
        } else {
            errors.change.pass1 = false;
        }

        if (this.state.data.change.pass2 === '') {
            errors.change.pass2 = true;
            submit_enable = false;
            errors.pass2Text = 'Введен некорректный пароль';
        } else {
            errors.change.pass2 = false;
        }

        if (this.state.data.change.pass3 === '') {
            errors.change.pass3 = true;
            submit_enable = false;
            errors.pass3Text = 'Введен некорректный пароль';
        } else {
            if (this.state.data.change.pass3 !== this.state.data.change.pass2) {
                errors.change.pass3 = true;
                errors.change.pass3Text = 'Пароли не совпадают';
            } else {
                errors.change.pass3 = false;
            }
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("old_password", this.state.data.change.pass1);
            formData.append("new_password", this.state.data.change.pass2);
            formData.append("new_password_repeat", this.state.data.change.pass3);

            await fetch(constants.API_URL + '/updateProfile', {
                method: "POST",
                body: formData,
                headers: {
                    'X-Auth-Token': localStorage.getItem('auth_key')
                }
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info2Show();
                } else if (response.message.old) {
                    errors.change.pass1 = true;
                    errors.change.pass1Text = response.message.old;
                    submit_enable = false;
                } else if (response.message.pass) {
                    errors.change.pass2 = true;
                    errors.change.pass2Text = response.message.pass;
                    submit_enable = false;
                } else {
                    errors.errorStatus = true;
                    errors.errorMessage = response.message;
                }
                /*if (response.status === "success") {
                    this.setState({
                        modal__successTitle: 'Готово'
                    });
                    this.setState({
                        modal__successText: 'Ваш пароль успешно изменен'
                    });
                    this.successShow()
                }

                if (response.status === "error") {
                    if (response.message.password !== undefined) {
                        errors.change.pass1 = true;
                        errors.change.pass1Text = response.message.password[0];
                    }

                    if (response.message.pass2 !== undefined) {
                        errors.change.pass2 = true;
                        errors.change.pass2Text = response.message.pass2[0];
                    }

                    if (response.message.pass2 !== undefined) {
                        errors.change.pass3 = true;
                        errors.change.pass3Text = response.message.pass2R[0];
                    }

                    this.setState({
                        errors: errors
                    });
                }*/

                this.setState({
                    loading: false
                });
            });
        }
    }

    submitFeedback = async () => {
        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.feedback.email === '') {
            errors.feedback.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.feedback.email)) {
                errors.feedback.email = true;
                submit_enable = false;
            } else {
                errors.feedback.email = false;
            }
        }

        if (this.state.data.feedback.name.length < 2) {
            errors.feedback.name = true;
            submit_enable = false;
        } else {
            errors.feedback.name = false;
        }

        /*if ((this.file.state.fileList !== '') && (this.file.state.fileList !== null) && (this.file.state.fileList.length !== 0)) {
            errors.feedback.onFilesChange3 = false;
        } else {
            errors.feedback.onFilesChange3 = true;
            submit_enable = false;
        }*/

        if (this.state.data.feedback.topic === null || this.state.data.feedback.topic === '') {
            errors.feedback.topic = true;
            submit_enable = false;
        } else {
            errors.feedback.topic = false;
        }

        if (this.state.data.feedback.message.length < 5) {
            errors.feedback.message = true;
            submit_enable = false;
        } else {
            errors.feedback.message = false;
        }

        this.setState({
            errors: errors
        });
        if (submit_enable === true) {
            this.setState({
                loading: true
            });

            const formData = new FormData();
            formData.append("email", this.state.data.feedback.email);
            formData.append("name", this.state.data.feedback.name);
            formData.append("subject", this.state.data.feedback.topic);
            formData.append("text", this.state.data.feedback.message);
            //formData.append("images", this.file.state.fileList);
            for (var x = 0; x < this.file.state.fileList.length; x++) {
                formData.append("images[]", this.file.state.fileList[x]);
            }

            await fetch(constants.API_URL + '/feedback', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info4Show();
                }

                if (response.error === 1) {
                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }
    }

    toggleTopicDropdown = () => {
        let errors = this.state.errors;
        errors.feedback.topic = false;
        this.setState({
            topicDropdown: !this.state.topicDropdown,
            errors: errors
        })
    }

    selectTopic = (topic, title) => {
        let data = this.state.data;
        data.feedback.topic = topic;
        let errors = this.state.errors;
        errors.feedback.topic = false;
        this.setState({
            data: data,
            errors: errors,
            topicDropdown: false,
            topicInput: title
        });
    }
    render() {
        return (
            <div className="modals__list">
                <div data-show={this.state.modal__close_site} className="modal__custom modal__custom-small modal__close_site">
                    <div className="modal__box">

                        <div className="modal__box-content">
                            <div className="modal__box-header d-flex align-items-end justify-content-between">
                                <p className="align-justify">
                                    За&nbsp;последний год Россельхознадзор ввел запрет на&nbsp;импорт кормовой продукции из&nbsp;ряда стран. Все предприятия Hill&rsquo;s, которые производят корма для животных, импортируемые в&nbsp;Россию, полностью соответствуют высочайшим стандартам качества и&nbsp;требованиям ЕАЭС, и&nbsp;не&nbsp;используют ГМО-содержащие компоненты. В&nbsp;связи с&nbsp;этим мы&nbsp;временно приостанавливаем работу программы &laquo;100% гарантия качества, консистенции и&nbsp;вкуса.&raquo;<br />
                                    <br />
Мы&nbsp;делаем все возможное, чтобы как можно скорее наладить поставки кормов, которые затронуло данное ограничение. Мы&nbsp;всегда готовы ответить на&nbsp;Ваши вопросы. Свяжитесь с&nbsp;нами в&nbsp;удобной для Вас форме: написать на&nbsp;наш e-mail: hills_russia@colpal.com, позвонить в&nbsp;Ветеринарный информационный центр Hill&rsquo;s по&nbsp;номеру&nbsp;&mdash; 8&nbsp;800&nbsp;200 11&nbsp;11 (Время работы: ежедневно с&nbsp;9.00 до&nbsp;21.00 (московское время). Звонок для всех регионов&nbsp;РФ бесплатный) или написать нам в&nbsp;личные сообщения в&nbsp;наших официальных группах в&nbsp;социальных сетях в&nbsp;VKontakte.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__feedback} className="modal__custom modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-header d-flex align-items-end justify-content-between">
                                <div className="modal__box-title">
                                    Обратная связь
                                </div>
                            </div>
                            <div className="modal__form">
                                <div className="form__list">
                                    <div className={'form__item ' + ((this.state.data.feedback.nameFocus === true || this.state.data.feedback.name !== '') ? 'form-focused' : '')}>
                                        <div className={['form__field', ((this.state.errors.feedback.name === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.nameInput.focus();
                                            }}>
                                                Имя
                                                        </div>
                                            <input placeholder="Введите имя" ref={(name) => { this.nameInput = name; }} type="text" name="name" autoComplete="off" onFocus={() => {
                                                let errors = this.state.errors;
                                                let data = this.state.data;
                                                data.feedback.nameFocus = true;
                                                errors.feedback.name = false;
                                                this.setState({ errors: errors, data: data });
                                            }} value={this.state.data.feedback.name} onChange={(name) => {
                                                let data = this.state.data;
                                                data.feedback.name = name.target.value;
                                                this.setState({ data: data });
                                            }} onBlur={() => {
                                                let data = this.state.data;
                                                data.feedback.nameFocus = false;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('feedback', 'name')
                                            }
                                        </div>
                                    </div>
                                    <div className={'form__item ' + ((this.state.data.feedback.emailFocus === true || this.state.data.feedback.email !== '') ? 'form-focused' : '')}>
                                        <div className={['form__field', ((this.state.errors.feedback.email === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.emailInput.focus();
                                            }}>
                                                E-mail
                                                        </div>
                                            <input placeholder="example@email.com" ref={(email) => { this.emailInput = email; }} type="text" name="email" onFocus={() => {
                                                let errors = this.state.errors;
                                                let data = this.state.data;
                                                data.feedback.emailFocus = true;
                                                errors.feedback.email = false;
                                                this.setState({ errors: errors, data: data });
                                            }} autoComplete="off" value={this.state.data.feedback.email} onChange={(email) => {
                                                let data = this.state.data;
                                                data.feedback.email = email.target.value;
                                                this.setState({ data: data });
                                            }} onBlur={() => {
                                                let data = this.state.data;
                                                data.feedback.emailFocus = false;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('feedback', 'email')
                                            }
                                        </div>
                                    </div>
                                    
                                    

                                    <div className={'form__item ' + ((this.state.topicInput !== '') ? 'form-focused' : '')}>
                                        <div className={['form__field form__select ', ((this.state.errors.feedback.topic === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="select__custom">
                                                <div className="form__label" onClick={() => {
                                                    this.setState({
                                                        topicDropdown: true
                                                    })
                                                }}>
                                                    Тема
                                                            </div>
                                                <div onClick={() => this.toggleTopicDropdown()} className="select__custom-input d-flex align-items-center">
                                                    <span>{this.state.topicInput}</span>
                                                    <div className="select__custom-arrow d-flex align-items-center justify-content-center">
                                                        <i className={'select__arrow ' + ((this.state.topicDropdown === true) ? 'active' : '')}></i>
                                                    </div>
                                                </div>
                                                <div className={'select__custom-dropdown ' + ((this.state.topicDropdown === true) ? 'active' : '')}>
                                                    <div className="select__custom-dropdown-list">
                                                        <div onClick={() => this.selectTopic(1, 'Регистрация в акции')} className={'select__custom-dropdown-item ' + ((this.state.data.feedback.topic === 1) ? 'active' : '')}>
                                                            Регистрация в акции
                                                                    </div>
                                                        <div onClick={() => this.selectTopic(2, 'Регистрация чека')} className={'select__custom-dropdown-item ' + ((this.state.data.feedback.topic === 2) ? 'active' : '')}>
                                                            Регистрация чека
                                                                    </div>
                                                        <div onClick={() => this.selectTopic(4, 'Вопросы о продукте')} className={'select__custom-dropdown-item ' + ((this.state.data.feedback.topic === 4) ? 'active' : '')}>
                                                            Вопросы о продукте
                                                                    </div>
                                                        <div onClick={() => this.selectTopic(5, 'Технические вопросы')} className={'select__custom-dropdown-item ' + ((this.state.data.feedback.topic === 5) ? 'active' : '')}>
                                                            Технические вопросы
                                                                    </div>
                                                        <div onClick={() => this.selectTopic(6, 'Другое')} className={'select__custom-dropdown-item ' + ((this.state.data.feedback.topic === 6) ? 'active' : '')}>
                                                            Другое
                                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                this.renderError('feedback', 'topic')
                                            }
                                        </div>
                                    </div>
                                    <div className={'form__item ' + ((this.state.data.feedback.messageFocus === true || this.state.data.feedback.message !== '') ? 'form-focused' : '')}>
                                        <div className={['form__field', ((this.state.errors.feedback.message === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.messInput.focus();
                                            }}>
                                                Сообщение
                                                        </div>
                                            <textarea placeholder="Текст обращения" ref={(mess) => { this.messInput = mess; }} name="message" autoComplete="off" onFocus={() => {
                                                let errors = this.state.errors;
                                                let data = this.state.data;
                                                data.feedback.messageFocus = true;
                                                errors.feedback.message = false;
                                                this.setState({ errors: errors, data: data });
                                            }} value={this.state.data.feedback.message} onChange={(message) => {
                                                let data = this.state.data;
                                                data.feedback.message = message.target.value;
                                                this.setState({ data: data });
                                            }} onBlur={() => {
                                                let data = this.state.data;
                                                data.feedback.messageFocus = false;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('feedback', 'message')
                                            }
                                        </div>
                                    </div>
                                    <div className="col-d-12 col-m-12 form__col">
                                        <div className="col-d-12 col-m-12 onFilesChange3">
                                        </div>
                                    </div>
                                    <div className="col-d-12 col-m-12 form__col">
                                        <div className="col-d-12 col-m-12 onFilesChange">
                                            <File runWidget={this.runWidget} ref={instance => { this.file = instance; }} fileErr={this.state.errors.feedback.onFilesChange3} onClickItem={item => {

                                                this.setState({
                                                    fileError: true
                                                });
                                                let errors = this.state.errors;
                                                errors.feedback.onFilesChange3 = item;
                                                this.setState({ errors: errors });
                                            }} />
                                            {
                                                this.renderError('feedback', 'onFilesChange3')
                                            }
                                        </div>
                                    </div>
                                    <div className="form__item form__item-actions d-flex justify-content-center">
                                        <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                            <div onClick={() => {
                                                this.submitFeedback();
                                            }} className="btn-blue size-min btn__custom d-flex align-items-center justify-content-center">
                                                <span>Отправить</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__del} className="modal__custom modal__custom-del modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Вы уверены, что хотите удалить аккаунт?
                            </div>
                            <div className="actions__wrap btn__list d-flex align-items-center justify-content-center">
                                <div onClick={() => {
                                    this.modalClose();
                                    this.props.runDel()
                                }} className="btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Да</span>
                                </div>
                                <div onClick={() => {
                                    this.modalClose();
                                }} className="btn__custom btn__custom-border d-flex align-items-center justify-content-center">
                                    <span>Нет</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__recovery} className="modal__custom modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-header d-flex align-items-end justify-content-between">
                                <div className="modal__box-title">
                                    Вспомнить пароль
                                </div>
                                <p>Введите Ваш e-mail адрес</p>
                            </div>
                            <div className="modal__form">
                                <div className="form__list">
                                    <div className={'form__item ' + ((this.state.data.recovery.emailFocus === true || this.state.data.recovery.email !== '') ? 'form-focused' : '')}>
                                        <div className={['form__field', ((this.state.errors.recovery.email === true) ? 'has-error' : '')].join(' ')}>
                                            <div className="form__label" onClick={() => {
                                                this.email2Input.focus();
                                            }}>
                                                E-mail
                                            </div>
                                            <input placeholder="e-mailexample@mail.com" ref={(email2) => { this.email2Input = email2; }} type="text" autoComplete="username" onFocus={() => {
                                                let data = this.state.data;
                                                data.recovery.emailFocus = true;
                                                let errors = this.state.errors;
                                                errors.recovery.email = false;
                                                this.setState({ errors: errors, data: data });
                                            }} onChange={(email) => {
                                                let data = this.state.data;
                                                data.recovery.email = email.target.value;
                                                this.setState({ data: data });
                                            }} onBlur={() => {
                                                let data = this.state.data;
                                                data.recovery.emailFocus = false;
                                                this.setState({ data: data });
                                            }} />
                                            {
                                                this.renderError('recovery', 'email')
                                            }
                                        </div>
                                    </div>
                                    <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                                        <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                            <div onClick={() => {
                                                this.submitRecovery();
                                            }} className="btn-blue size-min btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                                                <span>Отправить</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_1} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={() => {
                            window.location.replace('/');
                            this.modalClose();
                        }}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Спасибо
                            </div>
                            <div className="modal__header-text">
                                Мы&nbsp;отправили Ваш пароль<br />
                                на&nbsp;указанный&nbsp;E-mail
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_2} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Спасибо!
                            </div>
                            <div className="modal__header-text">
                                <p>Данные в&nbsp;Вашем профиле успешно обновлены</p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                <div onClick={() => {
                                    this.modalClose();
                                }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Продолжить</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_3} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={() => {
                            window.location.replace('/');
                            this.modalClose();
                        }}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Завершите<br />
                                регистрацию
                            </div>
                            <div className="modal__header-text">
                                <p>Чтобы завершить регистрацию, перейдите по&nbsp;ссылке, отправленной на&nbsp;Вашу электронную почту.</p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                <div onClick={() => {
                                    window.location.replace('/');
                                    this.modalClose();
                                }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Продолжить</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_4} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Спасибо за&nbsp;обращение
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Ваш вопрос будет обработан в&nbsp;течение 24&nbsp;часов.
                                </p>
                                <p>
                                    Ответ будет отправлен на&nbsp;указанный электронный адрес.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_5} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={() => {
                            this.modalClose();
                            localStorage.setItem('info5', 1);
                        }}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Уважаемые владельцы питомцев! 
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Мы&nbsp;временно приостановили обработку заявок по&nbsp;программе &laquo;100% гарантия качества, консистенции и&nbsp;вкуса&raquo;. Обработка заявок будет возобновлена после 20-го января 2022&nbsp;года. <br/>
                                    По&nbsp;всем одобренным заявкам выплаты пройдут согласно правилам акции, до&nbsp;31&nbsp;декабря 2021&nbsp;года. <br/>
                                    Мы&nbsp;всегда готовы ответить на&nbsp;Ваши вопросы. Свяжитесь с&nbsp;нами в&nbsp;удобной для Вас форме: напишите на&nbsp;<a href="malito:hills_russia@colpal.com">hills_russia@colpal.com</a> или в&nbsp;личные сообщения в&nbsp;наших официальных группах в&nbsp;социальных сетях в&nbsp;VKontakte, или позвоните в&nbsp;Ветеринарный информационный центр Hill&rsquo;s <a href="tel:88002001111">8 800 200 11 11</a> время работы: ежедневно с&nbsp;9.00 до&nbsp;21.00 (московское время). Звонок для всех регионов&nbsp;РФ бесплатный.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-show={this.state.modal__info_6} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Заявка ушла на модерацию
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Мы&nbsp;получили Вашу анкету. В&nbsp;ближайшее время вам будет отправлено электронное письмо о&nbsp;результате проверки.
                                </p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                <div onClick={() => {
                                    this.modalClose();
                                }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Закрыть</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_7} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Ваша учётная запись подтверждена
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Теперь вы&nbsp;можете авторизоваться на&nbsp;сайте<br />
                                    со&nbsp;своими учетными данными.
                                </p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                <div onClick={() => {
                                    this.modalClose();
                                }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Закрыть</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_8} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Возврат невозможен
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Согласно правилам возврата мы&nbsp;можем вернуть сумму
                                    за&nbsp;данный тип корма. Обратитесь по&nbsp;данному вопросу
                                    на&nbsp;горячую линию 88002001111
                                </p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                <div onClick={() => {
                                    this.modalClose();
                                }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Завершить заполнение</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_9} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>
                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Возврат невозможен
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Извините, по&nbsp;правилам акции Вы&nbsp;не&nbsp;можете претендовать на&nbsp;возмещение средств. Вес упаковки продукта не&nbsp;соответствует пункту 4.1.4 правил акции.
                                </p>
                            </div>
                            {/*<div className="col-d-12 col-m-12">
                                <div className="modal__btn d-flex justify-content-center">
                                    <div onClick={() => {
                                        *//*this.modalClose();*//*
                                        this.props.onClickItem(false);
                                        this.modalClose();
                                    }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                        <span>СОГЛАСЕН НА ОБМЕН</span>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>

                <div data-show={this.state.modal__info_10} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Возврат невозможен
                            </div>
                            <div className="modal__header-text">
                                <p>{this.state.rejectionReason}</p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                <div onClick={() => {
                                    this.modalClose();
                                }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Продолжить</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_11} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={() => {
                            window.location.replace('/applications');
                            this.modalClose();
                        }}>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__header-text">
                                <p>{this.state.rejectionReason}</p>
                            </div>
                            <div className="modal__btn d-flex justify-content-center">
                                {[
                                    (this.state.mUrl === '') ?
                                        <div onClick={() => {
                                            window.location.replace('/applications');
                                            this.modalClose();
                                        }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center" key="1">
                                            <span>Продолжить</span>
                                        </div> :
                                        <a href={[this.state.mUrl]} onClick={() => {
                                            window.location.replace('/applications');
                                            this.modalClose();
                                        }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center" target="_black" key="1">
                                            <span>Продолжить</span>
                                        </a>
                                ]}
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_12} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>
                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Возврат невозможен
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Согласно условиям акции при отсутствии загруженного чека, мы&nbsp;не&nbsp;можем вернуть вам деньги, но&nbsp;можем заменить корм
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_13} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>
                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Возврат невозможен
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Обращаем Ваше внимание, что оформление заявок на возврат корма Hill’s временно приостановлено. Если у Вас возникли вопросы, можете связаться с нами: напишите на hills_russia@colpal.com или в личные сообщения в наших официальных группах в социальных сетях в VKontakte, Одноклассники, или позвоните в Ветеринарный информационный центр Hill’s 8 800 200 11 11. Время работы: ежедневно с 9.00 до 21.00 (московское время). Звонок для всех регионов РФ бесплатный.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__info_99} className="modal__custom modal__custom-info modal__custom-small">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                        </div>
                        <div className="modal__box-content">
                            <div className="modal__box-title">
                                Возврат невозможен
                            </div>
                            <div className="modal__header-text">
                                <p>
                                    Согласно правилам возврата мы&nbsp;можем вернуть сумму
                                    за&nbsp;корм при условии, если в&nbsp;пачке осталось не&nbsp;менее 70% корма. Узнайте более детальную информацию по&nbsp;телефону Горячей линии 88002001111
                                </p>
                            </div>
                            <div className="col-d-12 col-m-12 d__flex">
                                <div className="col-d-6 col-m-12">
                                    <div className="modal__btn d-flex justify-content-center">
                                        <div onClick={() => {
                                            /*this.modalClose();*/
                                            this.props.onClickItem(false);
                                            this.modalClose();
                                        }} className="btn-gray size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                            <span>Изменить свой ответ</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-d-6 col-m-12">
                                    <div className="modal__btn d-flex justify-content-center">
                                        <div onClick={() => {
                                            this.modalClose();
                                            window.location.replace('/applications');
                                        }} className="btn-blue size-min btn__custom-dark d-flex align-items-center justify-content-center">
                                            <span>Завершить заполнение</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}