import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as constants from '../constants';
export default class Confirm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            login: false,
            auth_key: false
        }
    }

    componentWillMount() {
        let urlParams = new URLSearchParams(this.props.location.search);
        let result = urlParams.get('result');
        let auth_key = urlParams.get('auth_key');
        if (parseInt(result) === 1 && auth_key !== null) {
            this.getProfile(auth_key);
        }
    }
    getProfile = async (auth_key) => {

        await fetch(constants.API_URL + '/getProfile', {
            method: "GET",
            headers: {
                'X-Auth-Token': auth_key
            }
        }).then((response) => response.json()).then((response) => {
            //console.log(response);
            if (response.error === 0) {
                localStorage.setItem('auth_key', auth_key);
                localStorage.setItem('auth_name', response.result.name);
                localStorage.setItem('auth_userUuid', response.result.auth_userUuid);

                window.location.replace("/applications");
            } else {
                window.location.replace("/");
            }
        });
    }


    render() {

        return (
            <div></div>
        )
    }
}