import React from 'react'
import { Link } from 'react-router-dom';
import logoFooter from '../assets/img//hils_new_footer_logo.png';

export default class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nav: false
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className="footer">
                <div className="container d-flex align-items-center">
                    <div className="box-footent-content">
                        <div className="logo__box d-flex align-items-center">
                            <Link to="/" className="logo">
                                <img src={logoFooter} />
                            </Link>
                        </div>
                        <div className="footer-slogan">
                            <div className="footer-title">100% гарантия качества <br />продуктов Hill's</div>
                            <p>Остались недовольны продукцией?</p>
                            {[(localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) ? <Link key="3" to="/applications-return" className="btn-blue2" onClick={() => {
                                localStorage.removeItem('ApplicationStep');
                                localStorage.removeItem('ApplicationId');
                                localStorage.removeItem('threeStep');
                                localStorage.removeItem('article');
                            }}>Оформить заявку на возврат</Link> : <Link key="4" to="/login" className="btn-blue2" onClick={() => {
                                localStorage.removeItem('ApplicationStep');
                                localStorage.removeItem('ApplicationId');
                                localStorage.removeItem('threeStep');
                                localStorage.removeItem('article');
                            }}>Оформить заявку на возврат</Link>]}
                        </div>
                    </div>
                    <div className="box-footent-content">
                        <div className="footer-menu1">
                            <div className="footer-title">Навигация</div>
                            <ul>
                                <li>
                                    {[(localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) ? <Link key="5" to="/applications-return" onClick={() => {
                                        localStorage.removeItem('ApplicationStep');
                                        localStorage.removeItem('ApplicationId');
                                        localStorage.removeItem('threeStep');
                                    }}>Оформить возврат</Link> : <Link key="6" to="/login" onClick={() => {
                                        localStorage.removeItem('ApplicationStep');
                                        localStorage.removeItem('ApplicationId');
                                        localStorage.removeItem('threeStep');
                                        localStorage.removeItem('article');
                                    }}>Оформить возврат</Link>]}
                                </li>
                                <li>
                                    <a href="/#faq">FAQ</a>
                                </li>
                                <li><a href="Правила_Hills_гарантия_качества.pdf" target="_blank">Правила Акции</a></li>
                                <li><span onClick={() => {
                                    this.props.btnFeedbackShow();
                                }}>Задать вопрос</span></li>
                                <li onClick={() => {
                                    const el = document.getElementById('menu');
                                    el.scrollIntoView({ behavior: "smooth" });
                                }}><Link to="/feeding-recommendations" id="feedingRecommendations">Советы по питанию</Link></li>
                            </ul>
                        </div>
                        <div className="footer-menu2">
                            <div className="footer-title">Дополнительно</div>
                            <ul>
                                <li><a href="https://www.Hillspet.ru/legal-statement-and-privacy-policy" target="_blank">Конфиденциальность</a></li>
                                <li><a href="https://www.Hillspet.ru/cookie-policy" target="_blank">Cookies</a></li>
                                <li><a href="https://www.Hillspet.ru/" target="_blank">Владелец сайта</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container d-flex align-items-center copyright">© 2020 Хиллс Пет Нутришн, Инк. Все права защищены.</div>
            </div>
        );
    }
}
