import React from 'react'
import { Link } from 'react-router-dom';
import * as constants from '../constants';

export default class SlideMenu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nav: false
        };
    }


    componentDidMount() {
    }

    componentWillUnmount() {

    }

    logout = async() => {
        let errors = this.state.errors;
        let submit_enable = true;

                    localStorage.removeItem('auth_key');
                    window.location.reload();
        /*if(submit_enable === true){
            this.setState({
                loading: true
            });
            await fetch(constants.API_URL + '/logout', {
                method: "POST",
                headers:{
                    'X-Auth-Token': localStorage.getItem('auth_key')
                }
            }).then((response) => response.json()).then((response) => {

                if(response.error === 0){
                }

                this.setState({
                    loading: false
                });
            });
        }*/

    }

    render() {
        return (
            <div className="slide-menu">
                <div className="slide-menu-title">Личный кабинет</div>
                <ul className="slide-menu-items">
                    <li className={(this.props.page === 'Applications') ? 'active' : ''}>
                        <Link to="/applications">Мои заявки</Link>
                    </li>
                    <li className={(this.props.page === 'ProfileData') ? 'active' : ''}>
                        <Link to="/profile-data">Данные профиля</Link>
                    </li>
                </ul>
                <a href="/applications-return" className={'btn-gray2 col-d-12' + ((this.props.page === 'applicationReturn') ? '  active' : '')} onClick={() => {
                    localStorage.removeItem('ApplicationStep');
                    localStorage.removeItem('ApplicationId');
                    localStorage.removeItem('threeStep');
                    localStorage.removeItem('article');
                }}>Оформить возврат</a>
            </div>
        );
    }
}
