import React from 'react';
import { Link } from 'react-router-dom';
import minBgDog from '../assets/img/min-bg-dog.jpg';
import loader from '../assets/img/ajax-loader (3).gif';
import logoHeader from '../assets/img/hils_new_large_logo.png';
import * as constants from '../constants';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            submit: false,
            loading: false,
            passType: 'password',
            data: {
                login: {
                    email: '',
                    emailFocus: false,
                    password: '',
                    passwordFocus: false,
                    captch: '',
                    captchFocus: false
                }
            },
            errors: {
                login: {
                    email: false,
                    emailText: 'Введен некорректный e-mail',
                    password: false,
                    passwordText: 'Введен некорректный пароль',
                    captch: false,
                    captchText: 'Подтвердите, что Вы не робот!'
                }
            },
            topicInput: '',
            topicDropdown: false,
            f_file: null,
            f_file_label: 'Изображение'
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
    renderError = (form, field) => {
        switch (form) {
            case 'login':
                switch (field) {
                    case 'email':
                        if (this.state.errors.login.email === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.login.email = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.login.emailText}
                                </div>
                            )
                        }
                        break;
                    case 'password':
                        if (this.state.errors.login.password === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.login.password = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.login.passwordText}
                                </div>
                            )
                        }
                        break;
                }
                break;
        }
    }
    submitLogin = async () => {

        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.login.email === '') {
            errors.login.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.login.email)) {
                errors.login.email = true;
                submit_enable = false;
            } else {
                errors.login.email = false;
            }
        }

        if (this.state.data.login.password === '') {
            errors.login.password = true;
            submit_enable = false;
        } else {
            errors.login.password = false;
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.login.email);
            formData.append("password", this.state.data.login.password.replace(/\s/g, ""));
            await fetch(constants.API_URL + '/login', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    localStorage.setItem('auth_key', response.result.auth_key);
                    localStorage.setItem('auth_name', response.result.name);
                    localStorage.setItem('auth_userUuid', response.result.userUuid);
                    window.location.replace('/applications');
                }
                /*console.log(localStorage.getItem('auth_userUuid'));*/

                if (response.error === 1) {
                    if (response.message.password !== undefined) {
                        errors.login.password = true;
                        errors.login.passwordText = response.message.password;
                    }
                    if (response.message.login !== undefined) {
                        errors.login.email = true;
                        errors.login.emailText = response.message.login[0];
                    }

                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }

    }



    render() {
        return (
            <div className="section-login-reg">
                <div className="section-login-reg-left">
                    <Link to="/" className="login-logo">
                    <img src={logoHeader} />
                    </Link>
                    <div className="section-title">Вход в аккаунт</div>
                    <p>Войдите, чтобы отслеживать статус заявок</p>
                    <div className="form__list">
                        <div className={'form__item ' + ((this.state.data.login.emailFocus === true || this.state.data.login.email !== '') ? 'form-focused' : '')}>
                            <div className={['size-middle form__field', ((this.state.errors.login.email === true) ? 'has-error' : '')].join(' ')}>
                                <div className="form__label" onClick={() => {
                                    this.email1Input.focus();
                                }}>
                                    E-mail
                                            </div>
                                <input placeholder="example@email.com" ref={(email1) => { this.email1Input = email1; }} type="text" autoComplete="username" onFocus={() => {
                                    let data = this.state.data;
                                    data.login.emailFocus = true;
                                    let errors = this.state.errors;
                                    errors.login.email = false;
                                    this.setState({ errors: errors, data: data });
                                }} onChange={(email) => {
                                    let data = this.state.data;
                                    data.login.email = email.target.value;
                                    this.setState({ data: data });
                                }} onBlur={() => {
                                    let data = this.state.data;
                                    data.login.emailFocus = false;
                                    this.setState({ data: data });
                                }} />
                                {
                                    this.renderError('login', 'email')
                                }
                            </div>
                        </div>
                        <div className={'form__item ' + ((this.state.data.login.passwordFocus === true || this.state.data.login.password !== '') ? 'form-focused' : '')}>
                            <div className={['form__field size-middle form__field-pass ', ((this.state.errors.login.password === true) ? 'has-error' : '')].join(' ')}>
                                <div className="form__label" onClick={() => {
                                    this.pass1Input.focus();
                                }}>
                                    Пароль
                                            </div>
                                <input ref={(pass1) => { this.pass1Input = pass1; }} placeholder="Введите пароль" type={this.state.passType} autoComplete="current-password" onFocus={() => {
                                    let data = this.state.data;
                                    data.login.passwordFocus = true;
                                    let errors = this.state.errors;
                                    errors.login.password = false;
                                    this.setState({ errors: errors, data: data });
                                }} onChange={(password) => {
                                    let data = this.state.data;
                                    data.login.password = password.target.value;
                                    this.setState({ data: data });
                                }} onBlur={() => {
                                    let data = this.state.data;
                                    data.login.passwordFocus = false;
                                    this.setState({ data: data });
                                    }} />
                                <Link to="/recovery" className="link-recovery">Забыли пароль?</Link>

                                <i className={'icon__eye ' + ((this.state.passType === 'text') ? 'active' : '')} onClick={() => {
                                    if (this.state.passType === 'text') {
                                        this.setState({
                                            passType: 'password'
                                        })
                                    } else {
                                        this.setState({
                                            passType: 'text'
                                        })
                                    }
                                }}></i>
                                {
                                    this.renderError('login', 'password')
                                }
                            </div>

                        </div>
                        <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                            <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                <div onClick={() => {
                                    this.submitLogin();
                                }} className="btn__custom btn-blue size-middle d-flex align-items-center justify-content-center">
                                    <div className={'loader ' + ((this.state.loading === true) ? 'active' : '')}><img src={loader} /></div>
                                    <span>Войти</span>
                                </div>
                            </div>
                        </div>
                        <div className="form__link">
                            <p>Еще нет аккаунта? <a href="/registration">Зарегистрируйтесь</a></p>
                        </div>
                    </div>
                </div>
                <div className="section-login-reg-img">
                    <span className="logo-guarantee"></span>
                    <img src={minBgDog} className="minBgDog" /> 
                </div>
                {/*<div className={'loader ' + ((this.state.loading === true) ? 'active' : '')}><img src={loader}/></div>*/}
            </div>
        );
    }
}
