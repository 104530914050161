import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import SlideMenu from "./SlideMenu";
import Modals from "./Modals";

import "../assets/css/App.scss";
import * as constants from '../constants';

let initWidjet = false;
export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            submit: false,
            loaded: false,
            webp: true,
            needCheckUpload: 0,
            requests: [],
            data: [],
            emptyText: 'Заявок нет'
        }
        this.info10Show = this.info10Show.bind(this);
        this.btnFeedbackShow = this.btnFeedbackShow.bind(this);
        this.widget = this.widget.bind(this);
        this.info5Show = this.info5Show.bind(this);
    }
    btnFeedbackShow = () => {
        this.modals.feedbackShow();
    }
    info5Show = () => {
        this.modals.info5Show();
    }
    componentDidMount() {
        this.getRequests();
        if (localStorage.getItem('auth_key') && localStorage.getItem('auth_key') !== null && localStorage.getItem('auth_key') !== undefined) {
        } else {
            window.location.replace('/');
        }

    }
    info10Show = (mText) => {
        this.modals.info10Show(mText);
    }
    getRequests = async () => {
        await fetch(constants.API_URL + '/getRequests', {
            method: "GET",
            headers: {
                'X-Auth-Token': localStorage.getItem('auth_key')
            }
        }).then((response) => response.json()).then((response) => {
            if (response.error === 0) {
                console.log(response);
                this.setState({
                    loaded: false
                });

                if (response.result.requests !== null) {
                    this.setState({
                        requests: response.result.requests,
                        emptyText: 'Заявок нет'
                    });
                } else {
                    this.setState({
                        requests: [],
                        emptyText: 'Заявок нет'
                    });
                }
            }
        });
    }
    widget = () => {

        //console.log(localStorage.getItem('auth_userUuid'));
        console.log('needCheckUpload');
        console.log('auth_userUuid');
        console.log(localStorage.getItem('applicationId'));
        const widgetParams = (qw) => {
            return {
                api: 'https://api.apmcheck.ru',
                apiKey: '37a2303b-8f88-4fc1-9c00-d3f88e7852e6',
                meta: { 'utm': localStorage.getItem('applicationId')},
                channel: 'test-widget',
                userUuid: localStorage.getItem('auth_userUuid'),
                maxPhotoSizeInMiB: 10,
                styles: {
                    mainButton: 'custom-main-button',
                    manualQrButton: 'custom-manual-qr-button',
                    uploadPhotosButton: 'custom-upload-button',
                    submitManualQrButton: 'custom-submit-manual-qr-button',
                    addPhotoButton: 'custom-add-photo-button',
                    submitPhotosButton: 'custom-submit-photos-button'
                },
                i18n: {
                    labels: {
                        mainButton: 'сканировать qr-код чека',
                        manualQrButton: 'ввести данные вручную',
                        uploadPhotosButton: 'загрузить фото QR-кода с чека',
                        submitManualQrButton: 'отправить',
                        addPhotoButton: 'Загрузить фото',
                        submitPhotosButton: 'отправить'
                    },
                    screens: {
                        scanQr: {
                            header: 'Загрузка чека<span>Сканирование</span>',
                            subheader: 'Наведите камеру на QR-код'
                        },
                        manualInput: {
                            header: 'Загрузка чека<span>Ручной ввод</span>',
                            subheader: 'Введите данные с чека'
                        },
                        cameraNotAvailable: {
                            header: 'Загрузка чека',
                            subheader: 'Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства.<br><br>Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную'
                        },
                        qrParsed: {
                            header: 'Благодарим!',
                            subheader: 'Ваш чек принят в обработку.'
                        },
                        uploadFiles: {
                            header: 'Загрузка чека',
                            subheader: 'Добавьте фото чека (до 5 частей)',
                            fileTooLargeError: 'Файл больше 10 МБ. Загрузите файл меньшего размера.'
                        },
                        sentReceiptFailed: {
                            header: 'Не удалось отправить чек!'
                        }
                    },
                    errors: {
                        "0002-0008": "Ой, что-то пошло не так."
                    }
                },
                callbacks: {
                    onReceiptSentSuccess: function (res) {
                        this.getRequests();
                    },
                    onReceiptSentError: function (res) {
                        this.getRequests();
                    }
                }

            }
        }
        if (!initWidjet) {
            window.qrWidget.init("apm-widget", widgetParams(this));
            initWidjet = true;
        }
        document.getElementById('apm-scan-qr').click();

    }
    renderApplications = () => {
        if (this.state.requests.length > 0) {
            return (
                this.state.requests.map((code, index) => (
                    <div key={'winner_' + index.toString()} className="table__row d-flex align-items-start">
                        <div className="tb__cols">
                            <div className="tb__row ">
                                <div className="tb__col number">
                                    <span className="tb__label">Номер заявки: </span>Заявка #{code.id} от {code.date_create}
                                                            </div>
                                <div className="tb__col status">
                                    <span className="tb__label">Статус: </span><span className="status-text">
                                        <span className={'ico-circle-status ico-' + (code.status)}></span>{code.text}</span>
                                </div>
                            </div>
                            {code.desc &&
                                <div className="tb__row info">
                                    { code.desc }
                                </div>
                            }
                        </div>
                        {((code.status) === 2 && (code.step < 5)) &&
                            <div className="tb__cols box-btn-blue">
                            <Link to={
                                {
                                    pathname: "/applications-return/",
                                    step: code.step+1,
                                    id: code.id
                                }
                            } className="btn-blue size-min btn" onClick={()=>{
                                localStorage.removeItem('applicationId');
                                localStorage.setItem('applicationId', code.id);
                                localStorage.setItem('ApplicationStep', code.step+1);
                            }}>
                                Отправить заявку
                            </Link>
                            </div>
                        }
                        {((code.completed) === 0 && (code.step === 5)) &&
                            <div className="tb__cols box-btn-blue">
                            <a href={code.url
                            } className="btn-blue size-min btn" target="_black">
                                Продолжить заполнение
                            </a>
                            </div>
                        }
                        {/*this.setState.needCheckUpload = (((code.needCheckUpload) && (code.needCheckUpload === 1)) ? 1 : 0)
                    console.log(this.setState.needCheckUpload);*/}
                        {((code.status === 1) && (code.needCheckUpload)) &&
                            <div className="tb__cols box-btn-blue">
                            <Link onClick={() => {
                                localStorage.setItem('applicationId', code.id)
                                this.widget();
                            }} className="btn-blue size-min btn">
                                Продолжить заполнение
                            </Link>

                            </div>
                        }
                        {((code.status === 0) && (!code.needCheckUpload)) &&
                            <div className="tb__cols box-btn-blue">
                                <Link to={
                                    {
                                        pathname: "/applications-return/",
                                        step: code.step + 1,
                                        id: code.id,
                                        article: code.article,
                                        batchNumber: code.batchNumber,
                                        cost: code.cost / 100,
                                        shop: code.shop,
                                        address: code.address,
                                        problem: code.problem,
                                        comment: code.comment
                                    }
                                }  onClick={() => {
                                    localStorage.removeItem('applicationId');
                                }} className="btn-blue size-min btn">
                                    Продолжить заполнение
                            </Link>
                            </div>
                        }
                        {code.status === 3 &&
                            <div className="tb__cols">
                                <span className="tb__label"></span>
                            <button className="btn-white size-min btn" onClick={() => {
                                this.info10Show(code.reason);
                            }}>Причина отказа</button>
                            </div>
                        }
                    </div>
                ))
            )
        } else {
            return (
                <div className="table__row d-flex align-items-start">
                    <div className="tb__col tb__col-empty">
                        {
                            this.state.emptyText
                        }
                    </div>
                </div>
            )
        }
    }


    renderError = () => {
        if (this.state.emailError === true) {
            return (
                <div className="error__message" onClick={() => {
                    this.setState({ emailError: false });
                }}>
                    {this.state.emailErrorText}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="app page-lk page-footer-bottom">
                <Header info5Show={this.info5Show}/>
                <div className="container section-container">
                    <SlideMenu page={ 'Applications' } info5Show={this.info5Show}/>
                    <div className="slide-content slide-content-applications">
                        <div className="slide-content-container">
                            <div className="section-title">Мои заявки</div>
                            <div className="tab__pane tab__pane-billa fadeIn animated active">
                                <div className="table__wrap">
                                    <div className="table__head">
                                        <div className="table__row d-flex align-items-start">
                                            <div className="tb__col tb__th">
                                                Номер заявки
                                            </div>
                                             <div className="tb__col tb__th">
                                                 Статус
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table__body" data-count={this.state.requests.length}>
                                        <div className="table__scroll">
                                            <div className="table__rows">
                                                {
                                                    this.renderApplications()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'codes-loader ' + ((this.state.loading_winners === true) ? 'active' : '')}></div>
                                </div>
                                <div id="apm-widget" className="">
                        </div>
                                <div id="apm-scan-qr" className="btn btn-blue size-min custom-main-button" style={{ display: 'none' }}>
                                    <span>Загрузить чек на покупку</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer btnFeedbackShow={this.btnFeedbackShow} info5Show={this.info5Show}/>
                <Modals runWidget={this.runWidget} ref={instance => { this.modals = instance; }} />
            </div>
        );
    }
}
