import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import step1 from "../assets/img/1f.jpg";
import step2 from "../assets/img/2f.jpg";
import step3 from "../assets/img/3f.jpg";
import step4 from "../assets/img/4f.jpg";
import advantages1 from "../assets/img/advantages1.jpg";
import advantages2 from "../assets/img/advantages2.jpg";
import advantages3 from "../assets/img/advantages3.jpg";

import "../assets/css/App.scss";
export default class FeedingRecommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faq: 0
        }
    }
    btnReturn = () => {
        return (
            <Link to="/" className="btn-return">Оформить заявку на возврат</Link>
        )
    }
    toggleFaq = (numb) => {
        if (this.state.faq === numb) {
            this.setState({
                faq: 0
            })
        } else {
            this.setState({
                faq: numb
            })
        }
    }

    render() {
        return (
            <div className="app">
                <Header page={"feeding-recommendations"} />
                <div className="section-transition-rules">
                    <div className="container">
                        <div className="section-title">2222Как перевести вашего питомца <br />на новый корм?</div>
                        <ul>
                            <li>Постепенно вводите новый корм в&nbsp;течение 7&nbsp;дней;</li>
                            <li>Добавляйте новый корм к&nbsp;предыдущему корму вашего питомца, постепенно увеличивая долю нового корма до&nbsp;полного замещения предыдущего корма;</li>
                            <li>Всегда обращайтесь к&nbsp;инструкции по&nbsp;кормлению, указанной на&nbsp;упаковке с&nbsp;кормом.</li>
                        </ul>
                        <div className="section-transition-rules-items">
                            <div className="section-transition-rules-item"><img src={ step1 } /></div>
                            <div className="section-transition-rules-item"><img src={ step2 } /></div>
                            <div className="section-transition-rules-item"><img src={ step3 } /></div>
                            <div className="section-transition-rules-item"><img src={ step4 } /></div>
                        </div>
                    </div>
                </div>
                <div className="section-advantages">
                    <div className="container">
                        <div className="section-title">Преимущества рационов Hill's</div>
                        <div className="section-advantages-items">
                            <div className="section-advantages-item">
                                <div className="section-advantages-img"><img src={ advantages1 } /></div>
                                <div className="section-advantages-text"><p>
                                    <b>Питание, которое меняет жизнь, начинается с&nbsp;науки.</b> Корма Hill&rsquo;s разработаны в&nbsp;соответствии с&nbsp;индивидуальными потребностями вашей собаки. Рационы PRESCRIPTION DIET обеспечивают организм вашего питомца всеми необходимыми питательными веществами для долгой и&nbsp;счастливой жизни.
                                </p></div>
                            </div>
                            <div className="section-advantages-item">
                                <div className="section-advantages-img"><img src={advantages2} /></div>
                                <div className="section-advantages-text"><p>
                                    <b>Биологически обоснованное питание.</b> В&nbsp;основе рационов Hill&rsquo;s&nbsp;&mdash; передовые исследования биологии домашних животных и&nbsp;научно разработанные формулы для удовлетворения постоянно меняющихся уникальных потребностей питомцев.
                                </p></div>
                            </div>
                            <div className="section-advantages-item">
                                <div className="section-advantages-img"><img src={advantages3} /></div>
                                <div className="section-advantages-text"><p>
                                    <b>Специализированное питание со&nbsp;вкусом, перед которым невозможно устоять.</b> Рационы Hill&rsquo;s PRESCRIPTION DIET для собак разработаны, чтобы обеспечить вашего питомца питанием с&nbsp;великолепным вкусом и&nbsp;текстурами, перед которыми невозможно устоять.
                                </p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer page={"home"} />
            </div>
        );
    }
}
