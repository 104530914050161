import React from 'react';
import { Link } from 'react-router-dom';
import Modals from "./Modals";
import minBgDog from '../assets/img/min-bg-dog.jpg';
import loader from '../assets/img/loader.gif';
import logoHeader from '../assets/img/hils_new_large_logo.png';
import * as constants from '../constants';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            submit: false,
            loading: false,
            passType: 'password',
            data: {
                recovery: {
                    email: '',
                    emailFocus: false
                }
            },
            errors: {
                recovery: {
                    email: false,
                    emailText: 'Введен некорректный e-mail'
                }
            },
            topicInput: '',
            topicDropdown: false,
            f_file: null,
            f_file_label: 'Изображение'

        }
        this.info1Show = this.info1Show.bind(this);
    }


    info1Show = () => {
        this.modals.info1Show();
    }
    componentDidMount() {
    }

    componentWillUnmount() {

    }
    renderError = (form, field) => {
        switch (form) {
            case 'recovery':
                switch (field) {
                    case 'email':
                        if (this.state.errors.recovery.email === true) {
                            return (
                                <div className="error__message" onClick={() => {
                                    let errors = this.state.errors;
                                    errors.recovery.email = false;
                                    this.setState({ errors: errors });
                                }}>
                                    {this.state.errors.recovery.emailText}
                                </div>
                            )
                        }
                        break;
                }
                break;
        }
    }
    submitRecovery = async () => {
        let errors = this.state.errors;
        let submit_enable = true;
        if (this.state.data.recovery.captch === '') {
            errors.recovery.captch = true;
            submit_enable = false;
        } else {
            errors.recovery.captch = false;
        }
        if (this.state.data.recovery.email === '') {
            errors.recovery.email = true;
            submit_enable = false;
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.data.recovery.email)) {
                errors.recovery.email = true;
                submit_enable = false;
            } else {
                errors.recovery.email = false;
            }
        }

        this.setState({
            errors: errors
        });

        if (submit_enable === true) {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("email", this.state.data.recovery.email);

            await fetch(constants.API_URL + '/recovery', {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((response) => {
                if (response.error === 0) {
                    this.info1Show()
                }

                if (response.error === 1) {
                    if (response.message.login !== undefined) {
                        errors.recovery.email = true;
                        errors.recovery.emailText = response.message.login[0];
                    }
                    this.setState({
                        errors: errors
                    });
                }

                this.setState({
                    loading: false
                });
            });
        }
    }



    render() {
        return (
            <div className="section-login-reg">
                <div className="section-login-reg-left">
                    <Link to="/" className="login-logo">
                        <img src={logoHeader} />
                    </Link>
                    <div className="section-title">Вспомнить пароль</div>
                    <p>Введите Ваш e-mail адрес</p>
                    <div className="form__list">
                        <div className={'form__item ' + ((this.state.data.recovery.emailFocus === true || this.state.data.recovery.email !== '') ? 'form-focused' : '')}>
                            <div className={['form__field', ((this.state.errors.recovery.email === true) ? 'has-error' : '')].join(' ')}>
                                <div className="form__label" onClick={() => {
                                    this.email2Input.focus();
                                }}>
                                    E-mail
                                            </div>
                                <input placeholder="e-mailexample@mail.com" ref={(email2) => { this.email2Input = email2; }} type="text" autoComplete="username" onFocus={() => {
                                    let data = this.state.data;
                                    data.recovery.emailFocus = true;
                                    let errors = this.state.errors;
                                    errors.recovery.email = false;
                                    this.setState({ errors: errors, data: data });
                                }} onChange={(email) => {
                                    let data = this.state.data;
                                    data.recovery.email = email.target.value;
                                    this.setState({ data: data });
                                }} onBlur={() => {
                                    let data = this.state.data;
                                    data.recovery.emailFocus = false;
                                    this.setState({ data: data });
                                }} />
                                {
                                    this.renderError('recovery', 'email')
                                }
                            </div>
                        </div>
                        <div className="form__item form__item-actions d-flex align-items-center justify-content-center">
                            <div className={'form__action d-flex justify-content-center ' + ((this.state.loading === true) ? 'disabled' : '')}>
                                <div onClick={() => {
                                    this.submitRecovery();
                                }} className="btn-blue size-middle btn__custom btn__custom-dark d-flex align-items-center justify-content-center">
                                    <span>Отправить</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="section-login-reg-img">
                    <span className="logo-guarantee"></span>
                    <img src={minBgDog} className="minBgDog" />
                </div>
                <div className={'loader ' + ((this.state.loading === true) ? 'active' : '')}><img src={loader} /></div>
                <Modals runWidget={this.runWidget} ref={instance => { this.modals = instance; }} />
            </div>

        );
    }
}
