import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './Home'
import FeedingRecommendation from './FeedingRecommendation';
import FeedingRecommendation2 from './FeedingRecommendation2';
import Login from './Login';
import Recovery from './Recovery';
import Reg from './Reg';
import ProfileData from './ProfileData';
import Applications from './Applications';
import ApplicationsReturn from './ApplicationReturn';
import Confirm from './Confirm';

export default class Main extends React.Component {
    render() {
        return (
            <Switch>
                <Route key="home" exact path='/' component={Home} />
                <Route key="feeding-recommendations" path='/feeding-recommendations' component={FeedingRecommendation} />
                <Route path="/login" component={Login} />
                <Route path="/recovery" component={Recovery} />
                <Route path="/registration" component={Reg} />
                <Route path="/profile-data" component={ProfileData} />
                <Route path="/applications" component={Applications} />
                <Route path="/applications-return" component={ApplicationsReturn} />
                <Route path='/confirm' component={Confirm} />
                <Route path="/" component={Home} />
                <Redirect to="/" />
            </Switch>
        )
    }
}
